import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/Footer';
import Header from '../../component/Header';
import Menu from '../../component/Menu';
import useUsuario from '../../hooks/useUsuario';
import { homeGet, neumatico } from '../../services/neumaticos';

export default function Home(){
  const navigate = useNavigate();
  const {usuarioctx} = useUsuario();
  const [resumen,setResumen] = useState([]);
  
  useEffect(()=>{
    if(usuarioctx == null){
      navigate('login/');
    }else{
      (async()=>{
        console.log('consultar home');
        const respuesta = await homeGet();
        if(respuesta.rest==1){
          console.log(respuesta);
          setResumen(respuesta);
                  
        } 
    })();

    }

  },[])

  if(usuarioctx == null){
    return(<></>)
  }

return(
  <>      
<Header />
      <Menu />      
    <div>
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">Resumen</h1>
          </div>{/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
            </ol>
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    {/* Main content */}
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{resumen.asignado}</h3>
                <p>Neumáticos Asignados</p>
              </div>
              <div className="icon">
                <i className="fa fa-truck" />
              </div>
              <a href="/neumatico" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{resumen.disponible}</h3>
                <p>Neumáticos Disponibles</p>
              </div>
              <div className="icon">
                <i className="ion ion-aperture" />
              </div>
              <a href="/asignacion" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{resumen.reencauche}</h3>
                <p>Neumáticos para Reencauche</p>
              </div>
              <div className="icon">
                <i className="fa fa-retweet" />
              </div>
              <a href="/reencauche" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{resumen.recepcion}</h3>
                <p>Neumáticos para Recepcionar</p>
              </div>
              <div className="icon">
                <i className="fa fa-reply" />
              </div>
              <a href="/recepcion" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{resumen.scrap}</h3>
                <p>Neumáticos para Scrap</p>
              </div>
              <div className="icon">
                <i className="fa fa-trash nav-icon" />
              </div>
              <a href="/scrap" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>

            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{resumen.baja}</h3>
                <p>Neumáticos de Baja</p>
              </div>
              <div className="icon">
                <i className="fa fa-times" />
              </div>
              <a href="/scrap" className="small-box-footer">Mas información <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          {/* ./col */}
        </div>
      </div>
    </section>

  </div>
</div>
<Footer/>
</>
);

}