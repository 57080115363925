const URLBloqueReencaucheGetAll = process.env.REACT_APP_NEUMATICO_API_URL+"bloquereencauche";
const URLBloqueReencauchePaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"bloquereencauche/paginacion";
const URLBloqueReencauchePorId = process.env.REACT_APP_NEUMATICO_API_URL+"bloquereencauche/";
const URLBloqueReencaucheGuardarPost = process.env.REACT_APP_NEUMATICO_API_URL+"bloquereencauche/store";
const URLBloqueReencaucheCancelarPost = process.env.REACT_APP_NEUMATICO_API_URL+"bloquereencauche/cancelar";

export async function  bloqueReencaucheGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    const response = await fetch(URLBloqueReencaucheGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.bloquereencauche;
    }else{
        return null;
    }

}

export async function bloqueReencauchePaginacion(pagina){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLBloqueReencauchePaginacion,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function bloquePorId(idbloque){
    const requestOptions={
        method: "GET",
        headers: {"Content-Type": "application/json"}
    }
    const response = await fetch(URLBloqueReencauchePorId+idbloque,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.bloque;
    }else{
        return null;
    }
}

export async function bloquereencaucheGuardarPost(data){
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLBloqueReencaucheGuardarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function bloquereencaucheCancelarPost(data){
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLBloqueReencaucheCancelarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}


