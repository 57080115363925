import React from "react"
import Header from "../../component/Header";
import Menu from "../../component/Menu";

export default function SinPagina(){

    return(
        <>
        <Header/>
        <Menu/>
        <div>
        <section class="content">
      <div class="error-page">
        <h2 class="headline text-warning"> 404</h2>

        <div class="error-content">
          <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Página no encontrada.</h3>

          <p>
            No se encontro la página que estaba buscando.
            Mientras tanto puede <a href="/home">regresar a la página de inicio</a> o a otra página del menu.
          </p>
        </div>
      </div>
    </section>
            

        </div>
        </>
    );

}

