import React from "react";


function LlantaRepuesto({item,index,handleDragging}){
    const handleDragStart= (e) =>{
        e.dataTransfer.setData('id',item.idneumatico)
        handleDragging(true)
      }
    
      const handleDragEnd=() =>handleDragging(false)

    return(
                    <div draggable
                    onDragStart={handleDragStart} 
                    onDragEnd={handleDragEnd}
                    >
                        <div className="conten-card-llanta">
                            <img 
                            src="dist/img/llanta-repuesto.png"
                            //src="https://www.llantamaya.com/wp/wp-content/uploads/2019/09/01005101741-1.png"
                            alt="llanta"
                            className="llanta-repuesto"/>
                        </div>
                    </div>
                );
           


}

export default LlantaRepuesto;