import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import { recepcionPorId } from "../../services/recepcion";


export default function VerRecepcion(){
    const navigate = useNavigate();
    const[params] = useSearchParams();

    const [idrecepcion,setIdRecepcion] = useState(params.get('idrecepcion')?? 0);

    const[listaNeumatico,setListaNeumatico]=useState([]);
    const [fecha,setFecha] = useState('');
    const [proveedor,setProveedor] = useState('');
    const [guiaremitente,setGuiaRemitente] = useState('');
    const [ordencompra,setOrdencompra] = useState('');

    useEffect(()=>{
        (async()=>{
            const data=await recepcionPorId(idrecepcion);
            setFecha(data.fecha);
            setProveedor(data.proveedor);
            setGuiaRemitente(data.guiaremitente);
            setOrdencompra(data.ordencompra)
            
            console.log(data);
            setListaNeumatico(data.detalle);
        })();
    },[]);

    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Ver recepción</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active">Ver recepción</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Ver recepción</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Fecha</label>
                                                        <input type="text" value={fecha} className="form-control" placeholder="Ingrese el fecha" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Proveedor</label>
                                                        <input type="text" value={proveedor} className="form-control" placeholder="Ingresar proveedor" disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Guia remitente</label>
                                                        <input type="text" value={guiaremitente} className="form-control" placeholder="Ingrese la guia de remitente" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Orden de compra</label>
                                                        <input type="text" value={ordencompra} className="form-control" placeholder="Ingrese la orden de compra" disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Lista de neumáticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Id</th>
                                                                <th>Código</th>
                                                                <th>Marca</th>
                                                                <th>Medida</th>
                                                                <th>Condición</th>
                                                                <th>Modelo<br/> Reencauche</th>
                                                                <th>Ancho de <br/>Banda</th>
                                                                <th>Remanente</th>
                                                                <th>Observación</th>
                                                                <th>Quitar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listaNeumatico.map((neumatico,index) =>(
                                                                <tr>
                                                                    <td>{neumatico.idneumatico}</td>
                                                                    <td>{neumatico.codigo}</td>
                                                                    <td>{neumatico.marca}</td>
                                                                    <td>{neumatico.medida}</td>
                                                                    <td>
                                                                        <select className="form-control" defaultValue={neumatico.condicionmodificado} disabled={true} >
                                                                            <option value={0}>--Seleccione--</option>
                                                                            <option value={1}>Reencauchado</option>
                                                                            <option value={4}>Scrap</option>
                                                                        </select> 
                                                                    </td>
                                                                    <td><input type="text" className="form-control" value={neumatico.modeloreencauche} disabled={true} ></input></td>
                                                                    <td><input type="text" className="form-control" value={neumatico.anchobanda} disabled={true} ></input></td>
                                                                    <td><input type="text" className="form-control" value={neumatico.rem1+' - '+neumatico.rem2+' - '+neumatico.rem3} disabled={true} ></input></td>
                                                                    <td><input type="text" className="form-control" value={neumatico.observacion} disabled={true}></input></td>
                                                                    <td><button type="button" className="btn btn-danger" ><i className="fa fa-times" aria-hidden="true"></i></button> </td>
                                                                </tr>
                                                            ))}
                                                            
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div>
        </div>
        <Footer/>
</>
    );
}