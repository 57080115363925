import React, { useEffect, useState } from "react";
import { CSVLink} from 'react-csv';

export default function ExportarExcelNeumatico({data}){
    const labels = [
        {label:"idneumatico",key:"idneumatico"},
        {label:"codigo",key:"codigo"},
        {label:"marca",key:"marca"},
        {label:"disenio",key:"disenio"},	
        {label:"medida",key:"medida"},	
        {label:"reen",key:"reen"},		
        {label:"condicion",key:"condicion"},
        {label:"vehiculo",key:"vehiculo"}
        //anio	tapa	rem1	rem2	rem3	presion	estado

    ];

    return(
        <CSVLink  data={ data} filename="Neumaticos"  className="btn btn-secondary form-control"><i className="fa fa-file-excel" aria-hidden="true"></i> Exportar excel</CSVLink>
    );



} 