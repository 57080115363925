import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { neumaticoPorVehiculoGet } from "../../services/neumaticos";
import Select from "react-select";
import { vehiculoComboFiltro } from "../../services/vehiculo";
import { inspeccionGuardarPost } from "../../services/inspeccion";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Footer from "../../component/Footer";
import useUsuario from "../../hooks/useUsuario";
import { EmpleadoCombo } from "../../services/empleado";

export default function RegistrarInspeccion(){
    const navigate = useNavigate();
    const [unidad,setUnidad] = useState(0);
    const {usuarioctx} =useUsuario();

    const[fecha,setFecha] = useState('');
    const[tecnico,setTecnico] = useState('');

    const[cuenta,setCuenta] = useState('');
    const[kilometraje,setKilometraje] = useState('');
    const[horometro,setHorometro] = useState('');
    const[marca,setMarca] = useState('');
    const[tipo,setTipo] = useState('');
    const[hubodometro,setHubodometro] = useState('');
    const[configuracion,setConfiguracion] = useState('');

    const[listaNeumatico,setListaNeumatico] = useState([]);

    const [vehiculos,setVehiculos] = useState([]);
    const [validar,setValidar] = useState(true);

    const onFechaChange = e => setFecha(e.target.value);
    const onCuentaChange = e => setCuenta(e.target.value);
    const onKilometrajeChange= e => setKilometraje(e.target.value);
    const onHorometroChange = e => setHorometro(e.target.value);

    const onMarcaChange = e => setMarca(e.target.value);
    const onTipoChange = e => setTipo(e.target.value);
    const onHubodometroChange = e => setHubodometro(e.target.value);
    const onConfiguracionChange = e => setConfiguracion(e.target.value);
    const [empleados,setEmpleados] = useState([]);

    const onTecnicoChange = e => setTecnico(e.label);
    
    const onBusquedaEmpleado = e =>{
        if(e!=''){
            console.log(e);
            (async()=>{
              const dataempleado = await EmpleadoCombo(e);
              console.log(dataempleado);
              setEmpleados(dataempleado);
          })();
        }
    }

    const onUnidadChange = e =>{
        setUnidad(e.value);
        console.log(e.value);
        setCuenta(e.cuenta);
        setMarca(e.marca);
        setTipo(e.tipo);        
        (async()=>{
            const data =await neumaticoPorVehiculoGet(e.value);
            data.map((neumatico,index)=>{
                neumatico.rem1 = 0;
                neumatico.rem2 = 0;
                neumatico.rem3 = 0;
                neumatico.presion = 0;
                neumatico.presioncorregida = 0;
                neumatico.tipoaro = '';
                neumatico.estadoaro = '';
                neumatico.caracteristica= ''
                neumatico.observaciones = '';
            })
            console.log(data)
            setListaNeumatico(data);
        })();
    }

    const onBusquedaUnidad= e=>{
        if(e!=''){
          console.log(e);
          (async()=>{
            const datavehiculo = await vehiculoComboFiltro(e);
            console.log(datavehiculo);
            setVehiculos(datavehiculo);
        })();
        }
    }

    const onRem1Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem1=e.target.value;

        setListaNeumatico(actualizarlista);
    }
    const onRem2Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem2=e.target.value;

        setListaNeumatico(actualizarlista);
    }
    const onRem3Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem3=e.target.value;

        setListaNeumatico(actualizarlista);
    }
    const onPresionChange = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].presion=e.target.value;

        setListaNeumatico(actualizarlista);
    }

    const onPresionCorregidaChange = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].presioncorregida=e.target.value;

        setListaNeumatico(actualizarlista);
    }

    const onTipoAroChange = e =>{
        let actualizarlista = [...listaNeumatico];
        let index = e.target.dataset.index;
        actualizarlista[index].tipoaro=e.target.value;
        setListaNeumatico(actualizarlista);
    }
    
    const onEstadoAroChange = e =>{
        let actualizarlista = [...listaNeumatico];
        let index = e.target.dataset.index;
        actualizarlista[index].estadoaro=e.target.value;
        setListaNeumatico(actualizarlista);
    }

    const onCaracteristicaChange = e =>{
        let actualizarlista = [...listaNeumatico];
        let index = e.target.dataset.index;
        actualizarlista[index].caracteristica=e.target.value;
        setListaNeumatico(actualizarlista);
    }

    const onObservacionesChange = e =>{
        let actualizarlista = [...listaNeumatico];
        let index = e.target.dataset.index;
        actualizarlista[index].observaciones=e.target.value;
        setListaNeumatico(actualizarlista);
    }

    const handleGuardarInspeccion = e =>{
        e.preventDefault();
        if(validarFormulario()){
            const activo=1;
            const usuario=usuarioctx.idusuario;
            const data={unidad,cuenta,kilometraje,horometro,marca,tipo,hubodometro,fecha,configuracion,tecnico,activo,usuario,neumaticos:listaNeumatico};
            console.log(data); 
            (async()=>{
                const respuesta = await inspeccionGuardarPost(data);
                if(respuesta!=null){               
                    navigate('/inspeccion/');
                }else
                {
                    console.log("acceso incorrecto vuelve a intentar");
                }

            })();
        }else{
            setValidar(false);
        }
        
    }

    function validarFormulario(){
        let validar=true;
        if(unidad > 0 && cuenta != '' && marca != '' && tipo != '' && fecha != '' && tecnico != '' && listaNeumatico.length >0){
            validar=true;
        }else{
            validar=false;
        }
        return validar;
    }

    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Registrar inspección</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/inspeccion">Lista de inspección</a></li>
                                <li className="breadcrumb-item active">Registrar inspección</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Inspección</h3>
                                    </div>

                                    <form>
                                        <div className="card-body">
                                            <div className="alert alert-danger alert-dismissible" hidden={validar} >
                                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                            <h6><i class="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Placa(*)</label>
                                                        <Select options={vehiculos} defaultValue={unidad} onChange={onUnidadChange} onInputChange={onBusquedaUnidad}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Ruta/Cuenta(*)</label>
                                                        <input type="text" className="form-control" value={cuenta} onChange={onCuentaChange} placeholder="Ingrese la ruta o cuenta"/>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Kilometraje</label>
                                                        <input type="number" className="form-control" value={kilometraje} onChange={onKilometrajeChange} placeholder="Ingrese el kilometraje"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Horometro</label>
                                                        <input type="number" className="form-control" value={horometro} onChange={onHorometroChange} placeholder="Ingrese el horometro"/>
                                                    </div>
                                                </div>  
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Marca de la unidad(*)</label>
                                                        <input type="text" className="form-control" value={marca} onChange={onMarcaChange} placeholder="Ingrese la marca"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Tipo(*)</label>
                                                        <input type="text" className="form-control" value={tipo} onChange={onTipoChange} placeholder="Ingrese el tipo"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Hubodometro</label>
                                                        <input type="number" className="form-control" value={hubodometro} onChange={onHubodometroChange} placeholder="Ingrese el hubodometro"/>
                                                    </div>
                                                </div> 
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Fecha(*)</label>
                                                        <input type="date" className="form-control" value={fecha} onChange={onFechaChange} placeholder="Fecha"/>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Configuración de Eje</label>
                                                        <input type="text" className="form-control" value={configuracion} onChange={onConfiguracionChange} placeholder="Ingrese la configuración de eje"/>
                                                    </div>
                                                </div> 
                                                                                            
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Neumaticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Posicion</th>
                                                                <th>DOT</th>
                                                                <th>Codigo</th>
                                                                
                                                                <th>Marca</th>
                                                                <th>Diseño</th>
                                                                <th>Medida</th>
                                                                
                                                                <th>Condición</th>
                                                                <th>Modelo Reenc</th>
                                                                <th>Nº Reenc</th>
                                                            
                                                                <th className="bg-warning" style={{width:"100px"}}>REM 1</th>
                                                                <th className="bg-warning" style={{width:"100px"}}>REM 2</th>
                                                                <th className="bg-warning" style={{width:"100px"}}>REM 3</th>
                                                                <th style={{width:"150px"}}>Presión<br/>Encontrada</th>
                                                                <th style={{width:"150px"}}>Presión<br/>Corregida</th>
                                                                <th style={{width:"350px"}}>Tipo Aro</th>
                                                                <th style={{width:"150px"}}>Estado Aro</th>
                                                                <th style={{width:"150px"}}>Caracteristica</th>
                                                                <th style={{width:"150px"}}>Observaciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listaNeumatico.map((neumatico,index)=>(
                                                                
                                                                <tr>
                                                                    <td>{(neumatico.posicion > 200)?<span className="text-danger">Repuesto</span> : neumatico.posicion}</td>
                                                                    <td>{neumatico.dot}</td>
                                                                    <td>{neumatico.codigo}</td>
                                                                    
                                                                    <td>{neumatico.marca}</td>
                                                                    <td>{neumatico.disenio}</td>
                                                                    <td>{neumatico.medida}</td>
                                                                    
                                                                    <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                    <td>{neumatico.modeloreencauche}</td>
                                                                    <td>{neumatico.reencauche}</td>
                                                                    
                                                                    <td><input className="form-control" type="text" data-index={index} value={neumatico.rem1} onChange={onRem1Change}/></td>
                                                                    <td><input className="form-control" type="text" data-index={index} value={neumatico.rem2} onChange={onRem2Change}/></td>
                                                                    <td><input className="form-control" type="text" data-index={index} value={neumatico.rem3} onChange={onRem3Change}/></td>
                                                                    <td><input className="form-control" type="text" data-index={index} value={neumatico.presion} onChange={onPresionChange}/></td>
                                                                    <td><input className="form-control" type="text" data-index={index} value={neumatico.presioncorregida} onChange={onPresionCorregidaChange}/></td>
                                                                    <td>
                                                                        <select className="form-control" data-index={index} onChange={onTipoAroChange} >
                                                                            <option value={"NO SELECCINADO"}>--Seleccione--</option>
                                                                            <option value={"FIERRO"}>FIERRO</option>
                                                                            <option value={"ALUMINIO"}>ALUMINIO</option>
                                                                        </select> 
                                                                    </td>
                                                        <td><input className="form-control" type="text" data-index={index} value={neumatico.estadoaro} onChange={onEstadoAroChange}/></td>
                                                                    <td>
                                                                        <select className="form-control" data-index={index} onChange={onCaracteristicaChange} >
                                                                            <option value={"NORMAL"}>--Seleccione--</option>
                                                                            <option value={"DESGASTE IRREGULAR"}>DESGASTE IRREGULAR</option>
                                                                            <option value={"CORTE DE BANDA"}>CORTE DE BANDA</option>
                                                                            <option value={"CORTE EN EL FLANCO"}>CORTE EN EL FLANCO</option>
                                                                            <option value={"SEPARACION DE BR"}>SEPARACION DE BR</option>
                                                                            <option value={"PROXIMO A REENCAUCHE"}>PROXIMO A REENCAUCHE</option>
                                                                        </select> 
                                                                    </td>
                                                        <td><input className="form-control" type="text" data-index={index} value={neumatico.observaciones} onChange={onObservacionesChange}/></td>
                                                                </tr>
                                                                
                                                                    
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                    <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Técnico(*)</label>
                                                        <Select options={empleados} defaultInputValue={''} onInputChange={onBusquedaEmpleado} onChange={(e)=>onTecnicoChange(e)} />
                                                    </div>
                                                </div> 
                                                                                            
                                            </div>
                                                    <button className="btn btn-success" onClick={handleGuardarInspeccion}>Guardar Inspección</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                </section>


            </div>

        </div>
        <Footer/>
</>
    );
}