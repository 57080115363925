const URLConfiguracionEjePorIdVehiculo= process.env.REACT_APP_NEUMATICO_API_URL +"configuracioneje/byidvehiculo/";
const URLVehiculoGetComboPlaca = process.env.REACT_APP_NEUMATICO_API_URL + "vehiculo/comboplaca";
const URLAsignacionNeumaticoPorIdVehiculo= process.env.REACT_APP_NEUMATICO_API_URL +"neumatico/vehiculoasigancion/";

export async function configuracionEjePorIdVehiculo(idvehiculo){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLConfiguracionEjePorIdVehiculo+idvehiculo,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.configuracioneje;
    }else{
        return null;
    }

/*
   let listaunidadtipo={};
   if(idtipo==1){
    listaunidadtipo=
    {
        idtipo:1,
        imagen:"camion-2j.png",
        posiciones:[
            {idposicion:10,top:84,left:35},
            {idposicion:11,top:84,left:200},
            {idposicion:12,top:435,left:35},
            {idposicion:13,top:435,left:80},
            {idposicion:14,top:435,left:155},
            {idposicion:15,top:435,left:200}]
    };

   }else{
    listaunidadtipo=
    {
        idtipo:2,
        imagen:"carreta-2j.png",
        posiciones:[
            {idposicion:10,top:335,left:35},
            {idposicion:11,top:335,left:80},
            {idposicion:12,top:335,left:155},
            {idposicion:13,top:335,left:200},
            
            {idposicion:14,top:435,left:35},
            {idposicion:15,top:435,left:80},
            {idposicion:16,top:435,left:155},
            {idposicion:17,top:435,left:200}]
    };
}
*/

}

export async function vehiculoComboFiltro(placa){
    const data={placa};
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLVehiculoGetComboPlaca,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.vehiculo;
    }else{
        return null;
    }
}

export async function asigancionNeumaticoPorIdVehiculo(idvehiculo){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLAsignacionNeumaticoPorIdVehiculo+idvehiculo,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return [];
    }
    
}