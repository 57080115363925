import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import { neumaticoParaReencauche } from "../../services/neumaticos";
import { bloquePorId } from "../../services/reencauche";


export default function VerBloque(){
    const navigate = useNavigate();
    const[params] = useSearchParams();

    const [idbloque,setBloque] =useState(params.get('idbloque')?? 0);
    const [listaNeumatico,setListaNeumatico] = useState([]);
    const [fecha,setFecha] = useState('');
    const [ordencompra,setOrdenCompra] = useState('');
    const [requerimiento,setRequerimiento] = useState('');
    const [proveedor,setProveedor] = useState('');

    useEffect(()=>{
        (async()=>{
            const data = await bloquePorId(idbloque);
            console.log(data);
            setFecha(data.fecha);
            setOrdenCompra(data.ordencompra);
            setRequerimiento(data.requerimiento);
            setProveedor(data.proveedor);
            setListaNeumatico(data.detalle);
        })();
    },[]);

    return(
        <>      
        <Header />
        <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Envio a reencauche</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/reencauche">Reencauche</a></li>
                            <li className="breadcrumb-item active">Envio a reencauche</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Envio de Neumatico</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Fecha</label>
                                                        <input type="text" value={fecha} className="form-control" placeholder="Ingrese la fecha" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Orden de Compra</label>
                                                        <input type="text" value={ordencompra} className="form-control" placeholder="Ingrese la orden de compra" disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Requerimiento</label>
                                                        <input type="text" value={requerimiento} className="form-control" placeholder="Ingrese el requerimiento" disabled={true}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Proveedor</label>
                                                        <input type="text" value={proveedor} className="form-control" placeholder="Ingrese el proveedor" disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <label>Lista de neumáticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Condición</th>
                                                                <th>Medida</th>                                          
                                                                <th style={{width:"100px"}}>Quitar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listaNeumatico.map((neumatico) => (
                                                            <tr>
                                                                <td>{neumatico.idneumatico
                                                                }</td>
                                                                <td>{neumatico.codigo}</td>
                                                                <td>{neumatico.marca}</td>
                                                                <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                <td>{neumatico.medida}</td>
                                                                <td><button type="button" className="btn-sm btn-danger"><i className="fa fa-times" aria-hidden="true"></i></button></td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </section>

            </div>

        </div>
        <Footer/>
        </>
    );
}