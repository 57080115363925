const URLUsuarioLoginPost = process.env.REACT_APP_NEUMATICO_API_URL +"usuario/login";

export async function usuarioLoginPost(usuario,contrasenia){
    const data={usuario,contrasenia};
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json" },
        body: JSON.stringify(data)
    };
    const response = await fetch(URLUsuarioLoginPost,requestOptions)
    const responsejson = await response.json();
    return responsejson;
}