import React from "react"
import { Navigate, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
        
  const handleCerrarSesion = e =>{
    e.preventDefault();
    localStorage.removeItem('usuarioctx');
    navigate('/login/');
  }

  return (
           <div>
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/" className="nav-link">Home</a>
      </li>
    </ul>

    
    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
      
      <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="ion-android-exit" style={{fontSize:20}}></i>
          
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" className="dropdown-item" onClick={handleCerrarSesion} >
          <i className="ion-android-exit mr-4" style={{fontSize:20}}></i> <span className="text-danger">Cerrar sesión</span>
          </a>
        </div>
      </li>


    </ul>
  </nav>
</div>

        );
    
}
