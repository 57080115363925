import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Paginacion from "../../component/Paginacion";
import { neumaticoExcel, neumaticoPaginacion, neumaticoScrapExcel } from "../../services/neumaticos";
import ExportarExcelScrap from "./componentes/ExportarExcelScrap";
import ModalRechazar from "./componentes/ModalRechazar";


export default function Scrap(){
    const[listaNeumatico,setListaNeumatico]=useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    const [filtroestado,setFiltroEstado] = useState(4);
    const [filtrocodigo,setFiltroCodigo] = useState('');
    const [neumaticoseleccion,setNeumaticoSeleccion] = useState([]);
    const [data,setData] = useState([]);

    const onFiltroCodigo = e =>setFiltroCodigo(e.target.value);

    function ObtenerNeumaticos(pagina,codigo){
        (async()=>{
          const resultado = await neumaticoPaginacion(pagina,codigo,filtroestado);
          console.log(resultado);
          if(resultado == null){
            setListaNeumatico([]);
          setPaginacion([]);         

          }else{
            setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaNeumatico(resultado.neumatico);
          setPaginacion([]);
          let temp=[];
          let activo='active';
          for(let i=1;i<=resultado.paginastotal;i++){
            if(i==paginaactual){
            temp.push({i,activo});
            }else{
              temp.push({i,activo:''});
            }
          }
          setPaginacion(temp); 

          }

               
      })();
    }

    function handleConsultar(pagina){
        (async()=>{
          const resultado = await neumaticoPaginacion(pagina,'',filtroestado);
          console.log(resultado);
          setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaNeumatico(resultado.neumatico);           
      })();
      handlePaginacionActiva(pagina);
    }

    function handlePaginacionActiva(pagina){
        let temp=[...paginacion];
  
        temp.map((item)=>{
          if(item.i==pagina){
            item.activo='active';
          }else{
            item.activo='';
          }
        })
              setPaginacion(temp);
    }

    const handleAnterior = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual>1){
          handleConsultar(actual-1);
        }
    }
    
    const handleSiguiente = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual<paginatotal){
          handleConsultar(actual+1);
        }
    }

    const handleFiltrar = e =>{
        e.preventDefault();
        ObtenerNeumaticos(1,filtrocodigo);
        ObtenerDataExcel(filtrocodigo);
      }
  
      const handleLimpiar = e =>{
        e.preventDefault();
        setFiltroCodigo('');
        ObtenerNeumaticos(1,'');
        ObtenerDataExcel('');
      }

    const handleRechazarNeumatico = (neumatico) =>{
        setNeumaticoSeleccion(neumatico);
        //toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.');
    }  

    function ObtenerDataExcel(codigo){
        (async()=>{
          const resultado = await neumaticoScrapExcel(codigo,filtroestado);
          setData(resultado);            
      })();
      }


    useEffect(()=>{
        ObtenerNeumaticos(1,'');
        ObtenerDataExcel(filtrocodigo);
    },[]);


    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Scrap</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active">Scrap</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Lista de neumáticos para reencauche</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-md-3 col-sm-12'>
                                                Código
                                                <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder='Ingrese código a filtrar' value={filtrocodigo} onChange={onFiltroCodigo} />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i className="fas fa-check"></i></span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='row'>
                                                <div className='col-sm-6 col-md-4'>
                                                <button type='button' className='btn btn-success form-control' onClick={handleFiltrar} ><i className="fa fa-search" aria-hidden="true"></i> Buscar</button>
                                                </div>

                                                <div className='col-sm-6 col-md-4' >
                                                <button className='btn btn-primary form-control' onClick={handleLimpiar} ><i className="fa fa-eraser"></i> Limpiar</button>
                                                </div>

                                                <div className='col-sm-6 col-md-4'>
                                                    <ExportarExcelScrap data={data}/>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Codigo</th>
                                                    <th>Marca</th>
                                                    <th>condicion</th>
                                                    <th>Medida</th>
                                                    <th>Estado</th>
                                                    <th>Fecha Baja</th>
                                                    <th>Scrap</th>
                                                    <th>Rechazar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaNeumatico.map((neumatico)=>(
                                                    <tr>
                                                        <td>{neumatico.idneumatico}</td>
                                                        <td>{neumatico.codigo}</td>
                                                        <td>{neumatico.marca}</td>
                                                        <td>{(neumatico.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                        <td>{neumatico.medida}</td>
                                                        <td>{(neumatico.estado==1)?<label className='text-success'>Disponible</label>:(neumatico.estado==2)?<label className='text-primary'>Asignado</label>:(neumatico.estado==3)?<label className='text-warning'>Reencauche</label>:(neumatico.estado==4)? (neumatico.subestado==1)? <label className='text-danger'>Scrap</label>  :<label className='text-default'>(Baja)</label>    :<label className='text-default'>Sin estado validado</label>}</td>
                                                        <td>{neumatico.fechabaja}</td>
                                                        <td>
                                                            {(neumatico.subestado==1)? <a href={"/scrap/registro/?idneumatico="+neumatico.idneumatico} className="btn-sm btn-success"><i className="fa fa-trash
                                                            " aria-hidden="true"></i> Dar Baja</a>:<></> }                                                            
                                                        </td>
                                                        <td>
                                                            {(neumatico.subestado==1)? <button className="btn-sm btn-danger" data-toggle="modal" data-target="#modalRechazar" onClick={()=>handleRechazarNeumatico(neumatico)}><i className="fa fa-times" aria-hidden="true"></i></button> :<></> }
                                                            
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <div hidden={(paginacion.length>0)? true: false}>
                                            <h5 className='text-danger text-center'>No se encontron neumáticos.</h5>
                                        </div>
                                    </div>
                                    <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                                        <ul className="pagination pagination-sm m-0 float-right">
                                        <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                                        <Paginacion  paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                                        <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </section>


            </div>
            <ModalRechazar neumatico={neumaticoseleccion} obtenerneumaticos={ObtenerNeumaticos}/>
            
            
        </div>
        <Footer/>
</>
    );
}