const URLOrdenTrabajoGetAll = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajo";
const URLOrdenTrabajoPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajo/paginacion";
const URLOrdenTrabajoPorId = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajo/";
const URLOrdenTrabajoGuardarPost = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajo/store";
const URLOrdenTrabajoRealizar = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajo/realizarordentrabajo";
const URLOrdenTrabajoEliminar = process.env.REACT_APP_NEUMATICO_API_URL + "ordentrabajo/eliminar";

const URLOrdenTrabajoExcel = process.env.REACT_APP_NEUMATICO_API_URL+"ordentrabajoexcel";


export async function ordenTrabajoGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLOrdenTrabajoGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.ordentrabajo;
    }else{
        return null;
    }
}

export async function ordenTrabajoPaginacion(pagina,unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad,unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLOrdenTrabajoPaginacion,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function ordentrabajoPorId(idordentrabajo){
    const requestOptions={
        method: "GET",
        headers: {"Content-Type": "application/json"}
    }
    const response = await fetch(URLOrdenTrabajoPorId+idordentrabajo,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.ordentrabajo;
    }else{
        return null;
    }
}

export async function ordenTrabajoGuardarPost(data){
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLOrdenTrabajoGuardarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function ordenTrabajoRealizarPost(data){
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLOrdenTrabajoRealizar,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function observacionesGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    /*
    const response = await fetch(URLNeumaticoGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumaticos;
    }else{
        return null;
    }
    */
   const listaobservaciones=[
    {camporem:"reparacionrem",camposem:"reparacionsem",etiqueta:"Reparación de llanta",remolcador:false,semiremolque:false,campodesc:"reparaciondesc",descripcion:""},
    {camporem:"cambiorem",camposem:"cambiosem",etiqueta:"Cambio de llanta",remolcador:false,semiremolque:false,campodesc:"cambiodesc",descripcion:""},
    {camporem:"rotacionrem",camposem:"rotacionsem",etiqueta:"Rotación de llanta",remolcador:false,semiremolque:false,campodesc:"rotaciondesc",descripcion:""},
    {camporem:"presionrem",camposem:"presionsem",etiqueta:"Presión de aire",remolcador:false,semiremolque:false,campodesc:"presiondesc",descripcion:""},
    {camporem:"retirorem",camposem:"retirosem",etiqueta:"Retiro por bajo remanente",remolcador:false,semiremolque:false,campodesc:"retirodesc",descripcion:""},
    {camporem:"revisionrem",camposem:"revisionsem",etiqueta:"Revision de llantas de repuesto",remolcador:false,semiremolque:false,campodesc:"revisiondesc",descripcion:""},
    {camporem:"revisionarosrem",camposem:"revisionarossem",etiqueta:"Revision de Aros espárragos y tuercas",remolcador:false,semiremolque:false,campodesc:"revisionarosdesc",descripcion:""},
    {camporem:"alineamientorem",camposem:"alineamientosem",etiqueta:"Alineamiento y balanceo",remolcador:false,semiremolque:false,campodesc:"alineamientodesc",descripcion:""},
    {camporem:"revisiongeneralrem",camposem:"revisiongeneralsem",etiqueta:"Revisión general",remolcador:false,semiremolque:false,campodesc:"revisiongeneraldesc",descripcion:""}
   ];
   
   return listaobservaciones;
}

export async function ordentrabajoExcel(unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin){
    const data = {unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin}
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLOrdenTrabajoExcel,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.ordentrabajo;
    }else{
        return null;
    }
}

export async function ordentrabajoEliminar(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLOrdenTrabajoEliminar,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

