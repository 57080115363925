import React, { useState } from "react";
import useUsuario from "../../../hooks/useUsuario";
import { ordentrabajoEliminar } from "../../../services/ordenTrabajo";


export default function ModalEliminar({ordentrabajo,obtenerordenestrabajo}){
    const {usuarioctx} = useUsuario();

    const handleEliminar = e =>{
        e.preventDefault();

        const idordentrabajo = ordentrabajo.idordentrabajo;
        const usuario = usuarioctx.idusuario;

        const data={idordentrabajo,usuario};
        
        (async()=>{
            const respuesta = await ordentrabajoEliminar(data);
            if(respuesta==true){
                //mostrar aler success
            }else
            {
                //mostrar alerta de error
            }
            //llamar a la funcion de consultar scrap
            obtenerordenestrabajo(1,'','','','');

        })();
    }
    

    return(
        <div className="modal fade" id="modalEliminar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header bg-danger">
                    <h5 className="modal-title">Borrar orden trabajo</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>¿Esta seguro de desea eliminar la orden de trabajo <strong>{ordentrabajo.idordentrabajo}</strong>?</p>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleEliminar}>Aceptar</button>
                </div>
                </div>
            </div>
        </div>
    );
}