import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUsuario from "../../hooks/useUsuario";
import { usuarioLoginPost } from "../../services/usuario";

export default function Login(){
  const { usuarioctx,handleUsuarioCtxChange } = useUsuario();
  const navigate = useNavigate();
  const [usuario,setUsuario] = useState([]);
  const [contrasenia,setContrasenia] = useState([]);
  const [visibleEL,setVisibleEL] = useState(true);

  const onUsuarioChange = e =>setUsuario(e.target.value);
  const onContraseniaChange = e =>setContrasenia(e.target.value);

  const handleLogin = e =>{
    e.preventDefault();
    
    (async()=>{
      const resultado = await usuarioLoginPost(usuario,contrasenia);
      console.log(resultado);
      if(resultado.rest == 0){
        setVisibleEL(false);

      }else{
        console.log('login correcto');
        localStorage.setItem('usuarioctx',JSON.stringify(resultado.usuario));
        handleUsuarioCtxChange(resultado.usuario);
        navigate('/home/');

      }

    })();
  }

  useEffect(()=>{
    if(usuarioctx != null){
      if(usuarioctx.idusuario == null){
        navigate('/home/');
      }
    }
  },[])



    return(
        <div className="login-page" style={{minHeight:'466px',backgroundImage:`url('https://www.hagemsa.com/wp-content/uploads/2018/08/imagenret2.jpg')`,backgroundRepeat:'no-repeat' ,backgroundSize:'100% 100%'}}>

<div className="login-box" >

<div className="card card-outline card-danger">
  <div className="card-header text-center">
    <div>
    <img src="/dist/img/logo.png" className="brand-image img-circle" style={{opacity: '.8'}} />
    </div>
    <h4>Sistema de neumáticos</h4>
  </div>
  <div className="card-body">
    <p className="login-box-msg">Inicia sesión para empezar</p>

    <form>
      <div className="input-group mb-3">
        <input type="text" className="form-control" value={usuario} onChange={onUsuarioChange} placeholder="Usuario" />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-user"></span>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <input type="password" className="form-control" value={contrasenia} onChange={onContraseniaChange} placeholder="Password" />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <br/>
      <div className="row justify-content-end">
        

        <div className="col-6">
          <button type="button" className="btn btn-danger btn-block" onClick={handleLogin}>Iniciar Sessión</button>
        </div>

      </div>
      <br></br>
      <div className="alert alert-danger alert-dismissible" hidden={visibleEL}>
                  <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h6><i className="icon fas fa-ban"></i> Error de inicio de sesión!</h6>
                  El usuario o contraseña son incorrectos.
      </div>
    </form>
<br/>
    <p className="mb-1">
      <a href="http://extranet.hagemsa.org/">Ir a extranet</a>
    </p>
  </div>

</div>

      </div>

        </div>
        
    );
}  