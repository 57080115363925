const URLHomeGet = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/resumenhome";
const URLNeumaticoGetAll = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico";
const URLNeumatico = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico";
const URLNeumaticoExcel = process.env.REACT_APP_NEUMATICO_API_URL+"neumaticoexcel";
const URLNeumaticoPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/paginacion";
const URLNeumaticoPorId = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/";
const URLNeumaticoGuardarPost = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/store";
const URLNeumaticoBajaPost = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/baja";
const URLNeumaticoActualizarEstado = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/actualizarestado";
const URLNeumaticoEliminar = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/eliminar";
const URLNeumaticoPorEstadoGet = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/estado/";
const URLNeumaticoPoridvehiculoGet = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/vehiculo/";

const URLNeumaticoScrapExcel = process.env.REACT_APP_NEUMATICO_API_URL+"neumaticoscrapexcel";

const URLNeumaticoParaReencauche = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/parareencauche";
const URLNeumaticoParaReencauchePaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/parareencauchepaginacion"; 



const URLNeumaticoPendienteRecepcion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/pendienterecepcion"; 
const URLNeumaticoPendienteRecepcionPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/pendienterecepcionpaginacion";
const URLNeumaticoRecepcion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/recepcion"; 
const URLNeumaticoRecepcionPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"neumatico/recepcionpaginacion";


export async function neumaticoGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLNeumaticoGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }    
}

export async function neumatico(codigo,estado){
    const data = {codigo,estado}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLNeumatico,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoExcel(codigo,estado,unidad,maxrem,minrem){
    if(estado==1){
        estado = 'Disponible'
    }else if(estado==2){
        estado = 'Asignado'
    }else if(estado==3){
        estado = 'Reencauche'
    }else if(estado==4){
        estado = 'Scrap'
    }else{
        estado = ''
    }

    const data = {codigo,estado,unidad,maxrem,minrem}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLNeumaticoExcel,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoScrapExcel(codigo){
    const data = {codigo}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLNeumaticoScrapExcel,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumaticoscrap;
    }else{
        return null;
    }

}

export async function neumaticoPaginacion(pagina,codigo,estado,unidad,maxrem,minrem){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad,codigo,estado,unidad,maxrem,minrem}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLNeumaticoPaginacion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function neumaticoPorId(idneumatico){
    const requestOptions={
        method: "GET",
        headers: {"Content-Type": "application/json"}
    }
    const response = await fetch(URLNeumaticoPorId+idneumatico,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoPorEstadoGet(idestado){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLNeumaticoPorEstadoGet+idestado,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoPorVehiculoGet(idvehiculo){
    const requestOptions={
        method:"GET",
        headers:{"Content-Type":"application/json"}
    };
    
    const response = await fetch(URLNeumaticoPoridvehiculoGet+idvehiculo,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}


export async function neumaticoParaReencauche(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
        
    const response = await fetch(URLNeumaticoParaReencauche,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoParaReencauchePaginacion(pagina,codigo){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad,codigo}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
        
    const response = await fetch(URLNeumaticoParaReencauchePaginacion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function neumaticoPendienteRecepcion(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
        
    const response = await fetch(URLNeumaticoPendienteRecepcion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoPendienteRecepcionPaginacion(pagina,codigo){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad,codigo}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
        
    const response = await fetch(URLNeumaticoPendienteRecepcionPaginacion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}


export async function neumaticoRecepcion(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
        
    const response = await fetch(URLNeumaticoRecepcion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumatico;
    }else{
        return null;
    }
}

export async function neumaticoRecepcionPaginacion(pagina){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
        
    const response = await fetch(URLNeumaticoRecepcionPaginacion,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function neumaticoGuardarPost(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLNeumaticoGuardarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest!=0){
        return responsejson;
    }else{
        return null;
    }
}

export async function neumaticoBajaPost(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLNeumaticoBajaPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function neumaticoActualizarEstado(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLNeumaticoActualizarEstado,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function homeGet(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    const response = await fetch(URLHomeGet,requestOptions)
    const responsejson = await response.json();
    return responsejson;
}

export async function neumaticoEliminar(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLNeumaticoEliminar,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}