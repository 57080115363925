import React, { useState } from "react";
import useUsuario from "../../../hooks/useUsuario";
import { neumaticoActualizarEstado } from "../../../services/neumaticos";


export default function ModalRechazar({neumatico,obtenerneumaticos}){
    const {usuarioctx} = useUsuario();

    const handleRechazarNeumatico = e =>{
        e.preventDefault();
        const estadoactualizar=3;
        const subestadoactualizar = 1;
        const idneumatico = neumatico.idneumatico;
        const usuario = usuarioctx.idusuario;

        const data={idneumatico,estadoactualizar,subestadoactualizar,usuario};
        
        (async()=>{
            const respuesta = await neumaticoActualizarEstado(data);
            if(respuesta==true){
                //mostrar aler success
            }else
            {
                //mostrar alerta de error
            }
            //llamar a la funcion de consultar scrap
            obtenerneumaticos(1);

        })();


    }
    

    return(
        <div className="modal fade" id="modalRechazar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Eliminar neumatico de scrap</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>¿Esta seguro de eliminar el neumatico <strong>{neumatico.codigo}</strong> de scrap y enviarlo para reencauche?</p>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={handleRechazarNeumatico}>Aceptar</button>
                </div>
                </div>
            </div>
        </div>
    );
}