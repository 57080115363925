import React from "react";
import {Droppable} from "react-beautiful-dnd";
import ItemLlanta from "./ItemLlanta";
import Llanta from "./Llanta";
import LlantaTemporal from "./LlantaTemporal";

export default function Columna({droppableId,alto,ancho,tipoitem,items,isDragging,handleDragging,handleUpdateList}){
    const handleDragOver = (e) =>{
        e.preventDefault()
      }
    
      const handleDrop = (e) =>{
        e.preventDefault()
        const id = e.dataTransfer.getData('id')
        console.log(id)
        handleUpdateList(id,droppableId,tipoitem)//1: para asignar a la columna
        handleDragging(false)
      }
    return(
                    
        <div className={`${isDragging? 'dragging':'' }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
            padding:4,
            minWidth:ancho,
                height:alto,
                border:"2px dashed #887a7a",
                borderRadius:4,
                overflowY:"auto"}}
                >
                        {items?.map((item,index) => {
                            if(item.estado===droppableId && item.ver){
                                if(droppableId=='TRB'){
                                    return <LlantaTemporal key={item.id} item={item} index={index} handleDragging={handleDragging} />
                                }
                                return <ItemLlanta key={item.id} item={item} index={index} handleDragging={handleDragging} />
                                
                            }

                                
                        })}
                        
        </div>
    );


}


