import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { neumaticoPorVehiculoGet } from "../../services/neumaticos";
import Select from "react-select";
import { vehiculoComboFiltro } from "../../services/vehiculo";
import { inspeccionPorId } from "../../services/inspeccion";
import Footer from "../../component/Footer";
import Menu from "../../component/Menu";
import Header from "../../component/Header";

export default function VerInspeccion(){
    const navigate = useNavigate();
    const[params] = useSearchParams();

    const [idinspeccion,setIdInspeccion] = useState(params.get('idinspeccion')?? 0);

    const[inspeccion,setInspeccion] = useState([]);
    const[listaNeumatico,setListaNeumatico] = useState([]);

    useEffect(()=>{

        console.log(idinspeccion);
        if(idinspeccion!= 0){
            (async()=>{
                const data = await inspeccionPorId(idinspeccion);
                console.log(data);
                
                if(data != null){
                    setInspeccion(data);
                    setListaNeumatico(data.detalle);                    
                }
                
            })();
        }
    },[])
    
    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Ver inspección</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/inspeccion">Inspección</a></li>
                                <li className="breadcrumb-item active">ver inspección</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Inspección</h3>
                                    </div>

                                    <form>
                                        <div className="card-body">
                                        <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Ruta/Cuenta</label>
                                                        <input type="text" className="form-control" value={inspeccion?.cuenta} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Unidad</label>
                                                        <input type="text" className="form-control" value={inspeccion?.vehiculo?.placa}  disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Kilometraje</label>
                                                        <input type="number" className="form-control" value={inspeccion?.kilometraje} disabled={true} />
                                                    </div>
                                                </div> 
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Horometro</label>
                                                        <input type="number" className="form-control" value={inspeccion?.horometro} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Marca de la unidad</label>
                                                        <input type="text" className="form-control" value={inspeccion?.marca}  disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <input type="text" className="form-control" value={inspeccion?.tipo}  disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Hubodometro</label>
                                                        <input type="number" className="form-control" value={inspeccion?.hubodometro} disabled={true} />
                                                    </div>
                                                </div> 
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Fecha</label>
                                                        <input type="text" className="form-control" value={inspeccion?.fecha} disabled={true} />
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Configuración de Eje</label>
                                                        <input type="text" className="form-control" value={inspeccion?.configuracion} disabled={true} />
                                                    </div>
                                                </div> 
                                                
                                                
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Neumaticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Posicion</th>
                                                                <th>DOT</th>
                                                                <th>Codigo</th>
                                                                
                                                                <th>Marca</th>
                                                                <th>Diseño</th>
                                                                <th>Medida</th>
                                                                
                                                                <th>Condición</th>
                                                                <th>Modelo Reenc</th>
                                                                <th>Nº Reenc</th>
                                                            
                                                                <th className="bg-warning">REM_1</th>
                                                                <th className="bg-warning">REM_2</th>
                                                                <th className="bg-warning">REM_3</th>
                                                                <th style={{width:"150px"}}>Presión<br/>Encontrada</th>
                                                                <th style={{width:"150px"}}>Presión<br/>Corregida</th>
                                                                <th style={{width:"350px"}}>Tipo Aro</th>
                                                                <th style={{width:"150px"}}>Estado Aro</th>
                                                                <th style={{width:"150px"}}>Caracteristica</th>
                                                                <th style={{width:"150px"}}>Observaciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listaNeumatico.map((detalle,index)=>(
                                                                <tr>
                                                                    <td>{(detalle.posicion > 200)?<span className="text-danger">Repuesto</span> : detalle.posicion}</td>
                                                                    <td>{detalle.dot}</td>
                                                                    <td>{detalle.codigo}</td>
                                                                    
                                                                    <td>{detalle.marca}</td>
                                                                    <td>{detalle.disenio}</td>
                                                                    <td>{detalle.medida}</td>                                                                    
                                                                    <td>{(detalle.condicion==1)?"Nuevo":"Reencauchado"}</td>
                                                                    <td>{detalle.modeloreencauche}</td>
                                                                    <td>{detalle.reencauche}</td>
                                                                    <td><input className="form-control" type="text"  value={detalle.rem1} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.rem2} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.rem3} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.presion} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.presioncorregida} disabled={true}/></td>

                                                                    <td><input className="form-control" type="text"  value={detalle.tipoaro} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.estadoaro} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.caracteristica} disabled={true}/></td>
                                                                    <td><input className="form-control" type="text"  value={detalle.observaciones} disabled={true}/></td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Técnico</label>
                                                        <input type="text" className="form-control" value={inspeccion?.mecanico} disabled={true}/>
                                                    </div>
                                                </div>                                              
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                </section>


            </div>

        </div>
        <Footer/>
</>
    );
}