import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Paginacion from "../../component/Paginacion";
import { inspeccionExcel, inspeccionExcelDetallado, inspeccionPaginacion } from "../../services/inspeccion";
import ExportarExcelInspeccion from "./componentes/ExportarExcelInspeccion";
import ModalEliminar from "./componentes/ModalEliminar";
import ExportarExcelDetalladoInspeccion from "./componentes/ExportarExcelDetalladoInspeccion";

export default function Inspeccion(){
    const[listaInspeccion,setListaInspeccion] = useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    const [filtrounidad,setFiltroUnidad] = useState('');
    const [filtrofechainspeccioninicio,setFiltroFechaInspeccionInicio] = useState('');
    const [filtrofechainspeccionfin,setFiltroFechaInspeccionFin] = useState('');

    
    const [inspeccionseleccion,setInspeccionSeleccion] = useState([]);
    const [data,setData] = useState([]);
    const [datadetallado,setDataDetallado] = useState([]);

    const onFiltroUnidad = e => setFiltroUnidad(e.target.value);
    const onFiltroFechaInspeccionInicio = e => setFiltroFechaInspeccionInicio(e.target.value);
    const onFiltroFechaInspeccionFin = e => setFiltroFechaInspeccionFin(e.target.value);


    function ObtenerInspeccion(pagina,unidad,fechainspeccioninicio,fechainspeccionfin){
        (async()=>{
          const resultado = await inspeccionPaginacion(pagina,unidad,fechainspeccioninicio,fechainspeccionfin);
          console.log(resultado);
          if(resultado == null){
            setListaInspeccion([]);
            setPaginacion([]);            
          }else{
            setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaInspeccion(resultado.inspeccion);
          setPaginacion([]);
          let temp=[];
          let activo='active';
          for(let i=1;i<=resultado.paginastotal;i++){
            if(i==paginaactual){
            temp.push({i,activo});
            }else{
              temp.push({i,activo:''});
            }
          }
          setPaginacion(temp);             
          }

               
        })();
    }

    function ObtenerDataExcel(unidad,fechainspeccioninicio,fechainspeccionfin){
      (async()=>{
        const resultado = await inspeccionExcel(unidad,fechainspeccioninicio,fechainspeccionfin);
        setData(resultado);
      })();
    }

    function ObtenerDataExcelDetallado(unidad,fechainspeccioninicio,fechainspeccionfin){
      (async()=>{
        const resultado = await inspeccionExcelDetallado(unidad,fechainspeccioninicio,fechainspeccionfin);
        setDataDetallado(resultado);
      })();
    }

    const handleInspeccionSeleccion = (inspeccion) =>{
      setInspeccionSeleccion(inspeccion);
      //toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.');
    }

    function handleConsultar(pagina){
        (async()=>{
          const resultado = await inspeccionPaginacion(pagina);
          setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaInspeccion(resultado.inspeccion);           
        })();
        handlePaginacionActiva(pagina);
    }

    function handlePaginacionActiva(pagina){
        let temp=[...paginacion];
  
        temp.map((item)=>{
          if(item.i==pagina){
            item.activo='active';
          }else{
            item.activo='';
          }
        })
        setPaginacion(temp);
    }

    const handleAnterior = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual>1){
          handleConsultar(actual-1);
        }
    }
    const handleSiguiente = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual<paginatotal){
          handleConsultar(actual+1);
        }
    }

    const handleFiltrar = e =>{
        e.preventDefault();
        ObtenerInspeccion(1,filtrounidad,filtrofechainspeccioninicio,filtrofechainspeccionfin);
        ObtenerDataExcel(filtrounidad,filtrofechainspeccioninicio,filtrofechainspeccionfin);
        ObtenerDataExcelDetallado(filtrounidad,filtrofechainspeccioninicio,filtrofechainspeccionfin);
      }
  
      const handleLimpiar = e =>{
        e.preventDefault();
        setFiltroUnidad('');
        setFiltroFechaInspeccionInicio('');
        setFiltroFechaInspeccionFin('');
        ObtenerInspeccion(1,'','','');
        ObtenerDataExcel('','','');
        ObtenerDataExcelDetallado('','','');
      }

    useEffect(()=>{
        ObtenerInspeccion(1,filtrounidad,filtrofechainspeccioninicio,filtrofechainspeccionfin);
        ObtenerDataExcel(filtrounidad,'','');
        ObtenerDataExcelDetallado(filtrounidad,'','');
    },[]);

    return(
        <>      
<Header />
      <Menu />
<div>
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Inspección</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active">Reencauche</li>
                            </ol>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Lista de inspecciones</h3>
                                    <a href='/inspeccion/registro' className="btn btn-primary float-sm-right">+ Registrar Inspección</a>
                                </div>
                                <div className="card-body">
                <div className='row'>
                  <div className='col-md-3 col-sm-12'>
                    Unidad
                    <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder='Ingrese la placa' value={filtrounidad} onChange={onFiltroUnidad} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-truck"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Fecha inspeccion inicio
                    <div className="input-group mb-3">
                    <input type="date" className="form-control" placeholder='Ingrese la fecha a filtrar' value={filtrofechainspeccioninicio} onChange={onFiltroFechaInspeccionInicio} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Fecha inspeccion fin
                    <div className="input-group mb-3">
                    <input type="date" className="form-control" placeholder='Ingrese la fecha a filtrar' value={filtrofechainspeccionfin} onChange={onFiltroFechaInspeccionFin} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fas fa-calendar"></i></span>
                    </div>
                    </div>
                  </div>
                </div>
                <br/>
                <div className='row'>
                  
                    
                    <div className='col-sm-6 col-md-4'>
                    <button type='button' className='btn btn-success form-control' onClick={handleFiltrar} ><i className="fa fa-search" aria-hidden="true"></i> Buscar</button>
                    </div>

                    <div className='col-sm-6 col-md-4' >
                    <button className='btn btn-primary form-control' onClick={handleLimpiar} ><i className="fa fa-eraser"></i> Limpiar</button>
                    </div>
                    <div className='col-sm-6 col-md-2'>
                      <ExportarExcelInspeccion data={data} />
                    </div>
                    <div className='col-sm-6 col-md-2'>
                      <ExportarExcelDetalladoInspeccion data={datadetallado} />
                    </div>

                </div>
              

                
                
                </div>

                                <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Unidad</th>
                                                    <th>Fecha</th>
                                                    <th>Operador</th>
                                                    <th style={{width:"220px"}}>Ver</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaInspeccion.map((inspeccion) => (
                                                    <tr>
                                                        <td>{inspeccion.idinspeccion}</td>
                                                        <td>{inspeccion.vehiculo.placa}</td>
                                                        <td>{inspeccion.fecha}</td>
                                                        <td>{inspeccion.mecanico}</td>
                                                        <td><a href={'/inspeccion/ver/?idinspeccion='+inspeccion.idinspeccion} className="btn-sm btn-success"><i className="fa fa-eye" aria-hidden="true"></i> Ver</a> 
                                                        {(inspeccion.activo==1)? <button className="btn-sm btn-danger" data-toggle="modal" data-target="#modalEliminar" style={{padding:"3px",border:"none"}} onClick={()=>handleInspeccionSeleccion(inspeccion)}><i className="fa fa-trash" aria-hidden="true"> Eliminar</i></button> : <></>}
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <div hidden={(paginacion.length>0)? true: false}>
                                            <h5 className='text-danger text-center'>No se encontron inspecciones.</h5>
                                        </div>
                                </div>
                                <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                                        <ul className="pagination pagination-sm m-0 float-right">
                                        <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                                        <Paginacion paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                                        <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                                        </ul>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        
        </div>

        <ModalEliminar inspeccion={inspeccionseleccion} obtenerinspecciones={ObtenerInspeccion} />
    </div>
    <Footer/>
</>
    )
}