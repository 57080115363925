import React from "react";
import { useState,useEffect } from "react";

export default function Paginacion({paginaactual,paginatotal,paginacion,handleConsultar}){
    const [paginas,setPaginas] = useState(paginacion);
    
    
    
        return(
            (paginatotal < 20)?
            paginacion.map((item)=>(
                <li className={`page-item ${item.activo} `}><button className="page-link" onClick={()=>handleConsultar(item.i)}>{item.i}</button></li>
              ))
           
                

                
            :
            <>
            {(paginaactual!=1)?
            <>
             <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(1)}>{1}</button></li>
            
            </>:<></>}

            
            
            
            
            {(paginaactual-1>1)?
            <><li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(2)}>{2}</button></li> 
            </>:<></>}
            {(paginaactual-2>1)?
            <><li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(3)}>{3}</button></li>
            </>:<></>}


            {(paginaactual-5>1)?
            <><li className={"page-item"}><button className="page-link">{"..."}</button></li>
            <li className={"page-item"}><button className="page-link">{"..."}</button></li>
            <li className={"page-item"}><button className="page-link">{"..."}</button></li>            
            </>:<></>}
                        
            {(paginaactual-4>1)?
            <><li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginaactual-2)}>{paginaactual-2}</button></li>
            
            </>:<></>}

            {(paginaactual-3>1)?
            <>
            <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginaactual-1)}>{paginaactual-1}</button></li>
            </>:<></>}
            
            
            <li className={"page-item active"}><button className="page-link">{paginaactual}</button></li>



            {(paginaactual+3<paginatotal)?
            <>
            <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginaactual+1)}>{paginaactual+1}</button></li>
            </>:<></>}

            {(paginaactual+4<paginatotal)?
            <>
            <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginaactual+2)}>{paginaactual+2}</button></li>
            </>:<></>}
                        
            
            
            {(paginaactual+5<paginatotal)?
            <><li className={"page-item"}><button className="page-link">{"..."}</button></li>
            <li className={"page-item"}><button className="page-link">{"..."}</button></li>
            <li className={"page-item"}><button className="page-link">{"..."}</button></li>
            </>
            :<>
            </>}

            {(paginaactual+2<paginatotal)?
            <>
            <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginatotal-2)}>{paginatotal-2}</button></li>
            </>:<></>}

            {(paginaactual+1<paginatotal)?
            <>
            <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginatotal-1)}>{paginatotal-1}</button></li>
            
            </>:<></>}

            {(paginaactual!=paginatotal)?
            <>
             <li className={"page-item"}><button className="page-link" onClick={()=>handleConsultar(paginatotal)}>{paginatotal}</button></li>
            
            </>:<></>}
           
            


            
            

            </>
                

            
        
            
                

            
            
            
        );

        

    
}
