import React from 'react'

export default function Footer() {
    
        return (
           <div>
  <footer className="main-footer">
    <strong>Copyright © 2022 <a href="https://www.hagemsa.com/">Hagemsa</a>.</strong>
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 1.0.0
    </div>
  </footer>
</div>

        );
    
}
