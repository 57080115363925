import React from "react";
import Llanta from "./Llanta";

export default function Posicion({droppableId,ancho,items,tipoitem,isDragging,handleDragging,handleUpdateList}){
    const handleDragOver = (e) =>{
        e.preventDefault()
      }
    
    const handleDrop = (e) =>{
        e.preventDefault()
        const id = e.dataTransfer.getData('id')
        console.log(id)
        handleUpdateList(id,droppableId,tipoitem)//2:para solo asiganr a 1 posicion
        handleDragging(false)
      }

    return(
        <div className={`${isDragging? 'dragging':'' }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
            width:ancho,
            height:90,
            border:"2px dashed",
            borderRadius:5,
            backgroundColor:"#eeeeee"
                }}
                >
                        {items?.map((item,index) => {
                            if(item.estado===droppableId){                                
                                    return <Llanta key={item.id} item={item} index={index} ancho={ancho} handleDragging={handleDragging}  />;
                                
                            }

                                
                        })}
            <label className="text-center m-1 text-primary">{droppableId}</label>
        </div>

    );
}
