import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import useUsuario from "../../hooks/useUsuario";
import { neumaticoPendienteRecepcion } from "../../services/neumaticos";
import { recepcionGuardarPost } from "../../services/recepcion";

export default function RegistrarRecepcion(){
    const {usuarioctx} = useUsuario();
    const navigate = useNavigate();
    const[listaNeumatico,setListaNeumatico]=useState([]);
    const [fecha,setFecha] = useState('');
    const [proveedor,setProveedor] = useState('');
    const [guiaremitente,setGuiaRemitente] = useState('');
    const [validar,setValidar] = useState(true);
    const [ordencompra,setOrdenCompra] = useState('');

    const onFechaChange = e => setFecha(e.target.value);
    const onProveedorChange = e => setProveedor(e.target.value);
    const onGuiaRemitenteChange = e => setGuiaRemitente(e.target.value);
    const onOrdenCompraChange = e => setOrdenCompra(e.target.value);

    const onCondicionChange = e =>{
        console.log(e.target.value);
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].condicionmod=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onModeloReencaucheChange = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].modeloreencauche=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onAnchoChange = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].ancho=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onRem1Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem1=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onRem2Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem2=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onRem3Change = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].rem3=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const onObservacionChange = e =>{
        let actualizarlista = [...listaNeumatico];
        
        let index = e.target.dataset.index;
        actualizarlista[index].observacion=e.target.value;

        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const handleQuitarNeumatico = (id) =>{
        let actualizarlista = [...listaNeumatico];
        actualizarlista = actualizarlista.filter((item)=>item.idneumatico != id)
        setListaNeumatico(actualizarlista);
        console.log(actualizarlista);
    }

    const handleGuardarRecepcion = e =>{
        e.preventDefault();
        if(validarFormulario()){
            const activo = 1;
            const usuario = usuarioctx.idusuario;
            const data = {fecha,proveedor,guiaremitente,ordencompra,activo,usuario,neumaticos:listaNeumatico};

            (async()=>{
                const respuesta = await recepcionGuardarPost(data);
                if(respuesta==true){
                    navigate('/recepcion/');
                }else
                {
                    console.log("acceso incorrecto vuelve a intentar");
                }
            })();
        }else{
            setValidar(false);
        }
        
    }

    function validarFormulario(){
        let validar=true;
        if(fecha!='' && proveedor!='' && guiaremitente!='' && listaNeumatico.length > 0 && ordencompra!=''){
            validar=true;
        }else{
            validar=false;
        }
        return validar;
    }

    useEffect(()=>{
        (async()=>{
            const data=await neumaticoPendienteRecepcion();
            let actualizarlista = [...data];
            data.map((neumatico,index)=>{
                actualizarlista[index].rem1=actualizarlista[index].rem2=actualizarlista[index].rem3=0;
                actualizarlista[index]={...actualizarlista[index],condicionmod:0,codigoreencauche:'',observacion:'',modeloreencauche:'',ancho:''};
            })
            console.log(actualizarlista);
            setListaNeumatico(actualizarlista);
        })();
    },[]);

    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Registrar recepción</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active">Registrar recepción</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Registrar recepción</h3>
                                    </div>
                                    <form>
                                        <div className="card-body">
                                            <div className="alert alert-danger alert-dismissible" hidden={validar} >
                                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                            <h6><i class="icon fas fa-ban"></i>Debe llenar todos los campos para Guardar.</h6>                    
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Fecha(*)</label>
                                                        <input type="date" value={fecha} className="form-control" onChange={onFechaChange} placeholder="Ingrese el fecha"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Proveedor(*)</label>
                                                        <input type="text" value={proveedor} className="form-control" onChange={onProveedorChange} placeholder="Ingresar proveedor" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Guia remitente(*)</label>
                                                        <input type="text" value={guiaremitente} className="form-control" onChange={onGuiaRemitenteChange} placeholder="Ingrese la guia de remitente" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Orden de compra(*)</label>
                                                        <input type="text" value={ordencompra} className="form-control" onChange={onOrdenCompraChange} placeholder="Ingrese la orden de compra" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Lista de neumáticos</label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Id</th>
                                                                <th>Codigo</th>
                                                                <th>Marca</th>
                                                                <th>Medida</th>
                                                                <th>Condición</th>
                                                                <th>Modelo<br/> Reencauche</th>
                                                                <th>Ancho de <br/>Banda</th>
                                                                <th className="bg-warning" style={{width:"100px"}}>Rem1</th>
                                                                <th className="bg-warning" style={{width:"100px"}}>Rem2</th>
                                                                <th className="bg-warning" style={{width:"100px"}}>Rem3</th>
                                                                <th>Observación</th>
                                                                <th>Quitar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listaNeumatico.map((neumatico,index) =>(
                                                                <tr>
                                                                    <td>{neumatico.idneumatico}</td>
                                                                    <td>{neumatico.codigo}</td>
                                                                    <td>{neumatico.marca}</td>
                                                                    <td>{neumatico.medida}</td>
                                                                    <td>
                                                                        <select className="form-control" data-index={index} onChange={onCondicionChange} >
                                                                            <option value={0}>--Seleccione--</option>
                                                                            <option value={1}>Reencauchado</option>
                                                                            <option value={4}>Scrap</option>
                                                                        </select> 
                                                                    </td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.modeloreencauche} onChange={onModeloReencaucheChange}></input></td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.ancho} onChange={onAnchoChange}></input></td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.rem1} onChange={onRem1Change} ></input></td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.rem2} onChange={onRem2Change} ></input></td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.rem3} onChange={onRem3Change} ></input></td>
                                                                    <td><input type="text" className="form-control" data-index={index} value={neumatico.observacion} onChange={onObservacionChange}></input></td>
                                                                    <td><button type="button" className="btn btn-danger" onClick={()=>handleQuitarNeumatico(neumatico.idneumatico)}><i className="fa fa-times" aria-hidden="true"></i></button> </td>
                                                                </tr>
                                                            ))}
                                                            
                                                        </tbody>

                                                    </table>
                                                    <button className="btn btn-success" onClick={handleGuardarRecepcion}>Guardar Registro</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div>
        </div>
        <Footer/>
</>
    );
}