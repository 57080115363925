import React from "react";

export default function ItemLlanta({item,seleccionLlanta,handleDragging}){
    const handleDragStart= (e) =>{
        e.dataTransfer.setData('id',item.idneumatico)
        handleDragging(true)
      }
    
    
      const handleDragEnd=() =>handleDragging(false)


    return(
        <div draggable
         onDragStart={handleDragStart} 
         onDragEnd={handleDragEnd}

            style={{
              userSelect: "none",
              padding: "4px",
              margin: "0 0 8px 0",
              minHeight: "50px",
              backgroundColor: "#263B4A",
              color: "white",
              borderRadius: "4px"
            }}
          >
            <div className="conten-card">
                <div className="row">
                    <div className="col-2">
                    <img
                src="dist/img/item-llanta.jpg"
                alt="logo"
                className="logo"
              />
                    </div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-6">Codigo: {item.codigo}</div>
                            <div className="col-6">Marca: {item.marca}</div>
                            <div className="col-6">Medida: {item.medida}</div>
                            <div className="col-6">Remanente: {item.rem1}-{item.rem2}-{item.rem3}</div>
                            <div className="col-12">Condición: {(item.condicion==1)?<span className="text-success">Nuevo</span>:<span className="text-warning">Reencauchado</span>}</div>
                        </div>
                    </div>
                </div>
              
              
            </div>
          </div>
        
        
        
        
        
        /*<div onClick={() =>handleSeleccionClick(item)} className="row m-2" style={{backgroundColor:'#ffffff',borderRadius:'15px'}} >
            <div className="col-6"><label className="text-success">Codigo: </label>{item.codigo}</div>
            <div className="col-6"><label>Marca: </label>{item.marca}</div>
            <div className="col-6"><label>Estado: </label>{item.estado}</div>
            <div className="col-6"><label>Medida: </label>{item.medida}</div>
            <div className="col-6"><label>Cocada: </label>{item.cocada}</div>
            
        </div>*/
    );
}

