import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Paginacion from "../../component/Paginacion";
import { bloqueReencauchePaginacion} from "../../services/reencauche";
import ModalCancelarBloque from "./componentes/ModalCancelarBloque";

export default function BloquesReencauche(){
    const [listaBloqueReencauche,setListaBloqueReencauche] = useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    const [bloqueseleccion,setBloqueSeleccion] = useState([]);

    function ObtenerBloqueReencauche(pagina){
        (async()=>{
          const resultado = await bloqueReencauchePaginacion(pagina);
          console.log(resultado);
          if(resultado == null){
            setListaBloqueReencauche([]);
            setPaginacion([]);
          }else{
            setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaBloqueReencauche(resultado.bloquereencauche);
          setPaginacion([]);
          let temp=[];
          let activo='active';
          for(let i=1;i<=resultado.paginastotal;i++){
            if(i==paginaactual){
            temp.push({i,activo});
            }else{
              temp.push({i,activo:''});
            }
          }
          setPaginacion(temp);  

          }
        })();
    }

    function handleConsultar(pagina){
        (async()=>{
          const resultado = await bloqueReencauchePaginacion(pagina);
          setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaBloqueReencauche(resultado.bloquereencauche);           
        })();
        handlePaginacionActiva(pagina);
    }
  
      function handlePaginacionActiva(pagina){
        let temp=[...paginacion];
  
        temp.map((item)=>{
          if(item.i==pagina){
            item.activo='active';
          }else{
            item.activo='';
          }
        })
        setPaginacion(temp);
      }
  
      const handleAnterior = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual>1){
          handleConsultar(actual-1);
        }
      }
      const handleSiguiente = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual<paginatotal){
          handleConsultar(actual+1);
        }
      }

      const handleSeleccionarBloque = (bloque) =>{
        console.log(bloque);
        setBloqueSeleccion(bloque);
        //toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.');
    }

    useEffect(()=>{
        ObtenerBloqueReencauche(1);
    },[]);

    return(
        <>      
<Header />
      <Menu />

        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Lista de bloques enviados a reencauche</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/reencauche">Reencauche</a></li>
                            <li className="breadcrumb-item active">Bloques a reencauche</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Lista de bloques enviados a reencauche</h3>
                                        <a href='/reencauche/envio' className="btn btn-primary float-sm-right">Ir a reencauche</a>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    
                                                    <th>Fecha</th>
                                                    <th>Orden compra</th>
                                                    <th>Requerimiento</th>
                                                    <th>Ver</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaBloqueReencauche.map((bloque)=>(
                                                    <tr>
                                                        <td>{bloque.idbloquereencauche}</td>
                                                        
                                                        <td>{bloque.fecha}</td>
                                                        <td>{bloque.ordencompra}</td>
                                                        <td>{bloque.requerimiento}</td>
                                                        <td>
                                                            <a href={"/reencauche/ver/?idbloque="+bloque.idbloquereencauche} className="btn-sm btn-success"><i className="fa fa-eye" aria-hidden="true"></i> Ver</a>
                                                            {(bloque.estado==1)?<button className="btn-sm btn-danger" data-toggle="modal" data-target="#modalCancelarBloque" onClick={()=>handleSeleccionarBloque(bloque)} ><i className="fa fa-times" aria-hidden="true"></i> Cancelar</button>:'' }                                                            
                                                        </td>
                                                    </tr>
                                                )) }
                                                
                                            </tbody>
                                        </table>
                                        <div hidden={(paginacion.length>0)? true: false}>
                                            <h5 className='text-danger text-center'>No se encontron bloques enviados a reencauche.</h5>
                                        </div>
                                    </div>
                                    <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                                        <ul className="pagination pagination-sm m-0 float-right">
                                            <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                                            
                                            <Paginacion paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                                            <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <ModalCancelarBloque bloquereencauche={bloqueseleccion} obtenerBloqueReencauche={ObtenerBloqueReencauche} pagina={paginaactual}/>
        </div>
        <Footer/>
</>
    );
}