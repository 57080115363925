import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Paginacion from "../../component/Paginacion";
import { ordentrabajoExcel, ordenTrabajoPaginacion } from "../../services/ordenTrabajo";
import ExportarExcelOrdenTrabajo from "./componentes/ExportarExcelOrdenTrabajo";
import ModalEliminar from "./componentes/ModalEliminar";

export default function OrdenTrabajo() {
    const [listaOrdenTrabajo,setListaOrdenTrabajo] = useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    const [filtrounidad,setFiltroUnidad] = useState('');
    const [filtronumerosolicitud,setFiltroNumeroSolicitud] = useState('');
    const [filtrofechasolicitudinicio,setFiltroFechaSolicitudInicio] = useState('');
    const [filtrofechasolicitudfin,setFiltroFechaSolicitudFin] = useState('');
    const [ordentrabajoseleccion,setOrdenTrabajoSeleccion] = useState([]); 

    const [data,setData] = useState([]);

    const onFiltroUnidad = e => setFiltroUnidad(e.target.value);
    const onFiltroNumeroSolicitud = e =>setFiltroNumeroSolicitud(e.target.value);
    const onFiltroFechaSolicitudInicio = e => setFiltroFechaSolicitudInicio(e.target.value);
    const onFiltroFechaSolicitudFin = e => setFiltroFechaSolicitudFin(e.target.value);

    function ObtenerOrdenTrabajo(pagina,unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin){
      (async()=>{
        const resultado = await ordenTrabajoPaginacion(pagina,unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin);
        console.log(resultado);
        if(resultado==null){
          setData([]);
          setListaOrdenTrabajo([]);
          setPaginacion([]);
        }else{
          setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaOrdenTrabajo(resultado.ordentrabajo);
          setPaginacion([]);
          let temp=[];
          let activo='active';
          for(let i=1;i<=resultado.paginastotal;i++){
            if(i==paginaactual){
            temp.push({i,activo});
            }else{
              temp.push({i,activo:''});
            }
          }
          setPaginacion(temp);  
        }    
      })();
    }

    function ObtenerDataExcel(unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin){
      (async()=>{
        const resultado = await ordentrabajoExcel(unidad,numerosolicitud,fechasolicitudinicio,fechasolicitudfin);
        console.log('excel export');
        console.log(resultado);
        setData(resultado);            
    })();
    }

    const handleOrdenTrabajoSeleccion = (ordentrabajo) =>{
      setOrdenTrabajoSeleccion(ordentrabajo);
      //toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.');
    }

    function handleConsultar(pagina){
      (async()=>{
        const resultado = await ordenTrabajoPaginacion(pagina);
        setPaginaActual(resultado.paginaactual);
        setPaginaTotal(resultado.paginastotal);
        
        setListaOrdenTrabajo(resultado.ordentrabajo);           
      })();
      handlePaginacionActiva(pagina);
    }

    function handlePaginacionActiva(pagina){
      let temp=[...paginacion];

      temp.map((item)=>{
        if(item.i==pagina){
          item.activo='active';
        }else{
          item.activo='';
        }
      })
      setPaginacion(temp);
    }

    const handleAnterior = e =>{
      e.preventDefault();
      let actual = paginaactual;
      if(actual>1){
        handleConsultar(actual-1);
      }
    }
    const handleSiguiente = e =>{
      e.preventDefault();
      let actual = paginaactual;
      if(actual<paginatotal){
        handleConsultar(actual+1);
      }
    }

    const handleFiltrar = e =>{
      e.preventDefault();
      ObtenerOrdenTrabajo(1,filtrounidad,filtronumerosolicitud,filtrofechasolicitudinicio,filtrofechasolicitudfin);
      ObtenerDataExcel(filtrounidad,filtronumerosolicitud,filtrofechasolicitudinicio,filtrofechasolicitudfin);
    }

    const handleLimpiar = e =>{
      e.preventDefault();
      setFiltroNumeroSolicitud('');
      setFiltroFechaSolicitudInicio('');
      setFiltroFechaSolicitudFin('');
      ObtenerOrdenTrabajo(1,'','','','');
      ObtenerDataExcel('','','','');
    }

    useEffect(()=>{
      ObtenerOrdenTrabajo(1);
      ObtenerDataExcel('','','','');      
    },[]);



  return (
    <>      
<Header />
      <Menu />
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Orden de Trabajo</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Orden de trabajo</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Lista de orden de trabajo</h3>
                    <a href="/asignacion" className="btn btn-primary float-sm-right">+ Asignacion de neumático</a>
                  </div>
                  <div className="card-body">
                <div className='row'>
                <div className='col-md-3 col-sm-12'>
                    Unidad
                    <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder='Ingrese la placa de la unidad' value={filtrounidad} onChange={onFiltroUnidad} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-truck"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Número de solicitud
                    <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder='Ingrese número de solicitud a filtrar' value={filtronumerosolicitud} onChange={onFiltroNumeroSolicitud} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Fecha solicitud inicio
                    <div className="input-group mb-3">
                    <input type="date" className="form-control" placeholder='Ingrese número de solicitud a filtrar' value={filtrofechasolicitudinicio} onChange={onFiltroFechaSolicitudInicio} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Fecha solicitud fin
                    <div className="input-group mb-3">
                    <input type="date" className="form-control" placeholder='Ingrese número de solicitud a filtrar' value={filtrofechasolicitudfin} onChange={onFiltroFechaSolicitudFin} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fas fa-check"></i></span>
                    </div>
                    </div>
                  </div>
                </div>
                <br/>
                <div className='row'>
                  
                    
                    <div className='col-sm-6 col-md-4'>
                    <button type='button' className='btn btn-success form-control' onClick={handleFiltrar} ><i className="fa fa-search" aria-hidden="true"></i> Buscar</button>
                    </div>

                    <div className='col-sm-6 col-md-4' >
                    <button className='btn btn-primary form-control' onClick={handleLimpiar} ><i className="fa fa-eraser"></i> Limpiar</button>
                    </div>

                    <div className='col-sm-6 col-md-4'>
                    <ExportarExcelOrdenTrabajo data={data}/>
                     
                    </div>

                </div>
                </div>
                  <div className="card-body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Unidad</th>
                                <th>Nro. Solicitud</th>
                                <th>Fecha Solicitud</th>
                                <th>Estado OT</th>
                                <th>Fecha realizacion</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaOrdenTrabajo.map((orden) => (
                                <tr>
                                    <td>{orden.idordentrabajo}</td>
                                    <td>{orden.vehiculo.placa}</td>
                                    <td>{orden.numerosolicitud}</td>
                                    <td>{orden.fechasolicitud}</td>
                                    <td>{(orden.estado==1)?<label className='text-danger'>Pendiente</label>: <label className='text-success'>Realizado</label> }</td>
                                    <td>{orden.fecharealizacion}</td>
                                    <td><a href={'/verordentrabajo/?idordentrabajo='+orden.idordentrabajo} className="btn-sm btn-success"><i className="fa fa-eye" aria-hidden="true"></i> Ver</a><span> </span>
                                    {(orden.estado==2)? <a href={'/editarordentrabajo/?idordentrabajo='+orden.idordentrabajo} className="btn-sm btn-warning"><i className="fa fa-pencil" aria-hidden="true"></i> Editar</a>:<></> } <span> </span>
                                    {(orden.estado==1)? <a href={'/realizarordentrabajo/?idordentrabajo='+orden.idordentrabajo} className="btn-sm btn-primary"><i className="fa fa-sign-language" aria-hidden="true"></i> Realizar</a>:<></> } <span> </span>
                                    {(orden.estado==1)?<button className="btn-sm btn-danger" data-toggle="modal" data-target="#modalEliminar" style={{padding:"3px",border:"none"}} onClick={()=>handleOrdenTrabajoSeleccion(orden)}><i className="fa fa-trash" aria-hidden="true"> Eliminar</i></button> :<></>}
                               
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div hidden={(paginacion.length>0)? true: false}>
                      <h5 className='text-danger text-center'>No se encontron neumaticos.</h5>
                    </div>
                  </div>
                  <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                      <Paginacion paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                      <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                    </ul>
                  </div>
                    
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ModalEliminar ordentrabajo={ordentrabajoseleccion} obtenerordenestrabajo={ObtenerOrdenTrabajo} />

    </div>
    <Footer/>
</>
  );
}
