import React from "react";
import useUsuario from "../../../hooks/useUsuario";
import { bloquereencaucheCancelarPost } from "../../../services/reencauche";

export default function ModalCancelarBloque({bloquereencauche,obtenerBloqueReencauche,pagina}){
    const {usuarioctx} = useUsuario();

    const handleCancelarBloque = e =>{
        e.preventDefault();
        const usuario = usuarioctx.idusuario;
        const idbloquereencauche = bloquereencauche.idbloquereencauche;

        const data={idbloquereencauche,usuario};
        
        (async()=>{
            const respuesta = await bloquereencaucheCancelarPost(data);
            if(respuesta==true){
                //mostrar aler success
            }else
            {
                //mostrar alerta de error
            }
            //llamar a la funcion de consultar scrap
            obtenerBloqueReencauche(pagina);

        })();
        

    }

    return(
        <div className="modal fade" id="modalCancelarBloque" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Cancelar envio de bloque de reencauche</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>¿Esta seguro de desea cancelar el envio del bloque de reencauche?</p>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={handleCancelarBloque}>Aceptar</button>
                </div>
                </div>
            </div>
        </div>
    )
    
}