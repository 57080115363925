import Reac, { useEffect, useState } from "react";
import { neumaticoPorEstadoGet, neumaticoPorVehiculoGet } from "../../services/neumaticos";
import { asigancionNeumaticoPorIdVehiculo, configuracionEjePorIdVehiculo, vehiculoComboFiltro } from "../../services/vehiculo";
import Columna from "./componentes/Columna";
import Posicion from "./componentes/Posicion";
import Select from "react-select";
import { ordenTrabajoGuardarPost } from "../../services/ordenTrabajo";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import Menu from "../../component/Menu";
import Header from "../../component/Header";
import useUsuario from "../../hooks/useUsuario";
import Repuesto from "./componentes/Repuesto";
import ExportarExcelAsignacionNeumatico from "./componentes/ExportarExcelAsignacionNeumatico";

let llantaseleccion = "";

export default function Asigancion() {
    const navigate = useNavigate();
    const [unidad,setUnidad] = useState(0);
    const {usuarioctx} =useUsuario(); 
    
    const [isDragging, setIsDragging] = useState(false);
    const [listaNeumaticoDisponible, setListaNeumaticoDisponible] = useState([]);
    const [distribucionUnidad,setDistribucionUnidad] = useState([]);
    const [vehiculos,setVehiculos] = useState([])
    const [dataasigancion, setDataAsignacion] = useState([]);
    const [filtroneumatico,setFiltroNeumatico] = useState('');

    const onFiltroNeumaticoChange = e =>{
      console.log(e.target.value);
      setFiltroNeumatico(e.target.value);

      listaNeumaticoDisponible.map((item,index)=>{
        if(item.estado=='DSP'){
          if(item.codigo.includes(e.target.value)){
            item.ver=true;
          }else{
            item.ver=false;
          }
        }
      })
      console.log(listaNeumaticoDisponible);
    }
   
    const onUnidadChange = e =>{
      setFiltroNeumatico('');
        setUnidad(e.value);
        console.log(e.value);
        //por el id de la unidad traer la la distribucion del tipo
        (async()=>{
            const dataposicion = await configuracionEjePorIdVehiculo(e.value);
            console.log(dataposicion);
            setDistribucionUnidad(dataposicion);
            
            //obtener la aisgancion para excel
            const asignacion = await asigancionNeumaticoPorIdVehiculo(e.value);
            console.log(asignacion);
            setDataAsignacion(asignacion);
        })();

        (async()=>{
          const data = await neumaticoPorEstadoGet(1);
          data.map((item,index)=>{
            item.estado='DSP';
            item.ver = true;
          })
          const datavehiculo = await neumaticoPorVehiculoGet(e.value);
          console.log(datavehiculo);
          datavehiculo.map((neumatico,index)=>{
            neumatico.estado=neumatico.posicion;
            neumatico.ver = true;
            data.push(neumatico);
          })
          setListaNeumaticoDisponible(data);
          console.log(data);
          //setDistribucionUnidad(dataposicion);            
        })();


    }

    const onBusquedaUnidad= e=>{
      if(e!=''){
        (async()=>{
          const datavehiculo = await vehiculoComboFiltro(e);
          setVehiculos(datavehiculo);
  
      })();
      }
    }
  const handleDragging = (dragging) => setIsDragging(dragging);

  const handleUpdateList = (id, droppableId, ubicacion) => {
    if (ubicacion === 2) {
      //solo 1 llanta por posicion
      let neumatico = listaNeumaticoDisponible.find(
        (item) => item.estado == droppableId
      );
      if (neumatico !== undefined) {
        alert(
          "No puede asignar el neumatico a una posicion con neumatico asignado. Primero libere la posicion"
        );
        return;
      }
    }
    let card = listaNeumaticoDisponible.find((item) => item.idneumatico == id);

    if (card && card.estado != droppableId) {
      card.estado = droppableId;
      let listadisponible = [...listaNeumaticoDisponible];
      listadisponible = listadisponible.filter((item) => item.idneumatico != id);
      listadisponible.push(card);
      console.log(listadisponible);
      setListaNeumaticoDisponible(listadisponible);
    }
  };

  const handleGuargarOrdenTrabajo = e =>{
    let listatrabajo=listaNeumaticoDisponible.filter((item)=>item.estado=='TRB')
    if(listatrabajo.length>0){
      alert(
        "No debe de tener neumáticos libres en el área de trabajo."
      );
    }else if(unidad==0){
      alert(
        "Debe seleccionar un vehiculo primero antes de guardar."
      );
    }
    else{
    //let listaasigando=[...listaNeumaticoDisponible];
    //let listareencauche=[...listaNeumaticoDisponible];
    //let listascrap=[...listaNeumaticoDisponible];

    console.log('Neumaticos asignados');    
    let listaasigando=listaNeumaticoDisponible.filter((item)=>item.estado>=0)
    console.log(listaasigando);

    console.log('Neumaticos para reencauche 2 REN');
    let listareencauche=listaNeumaticoDisponible.filter((item)=>item.estado=='REN')
    console.log(listareencauche);

    console.log('Neumaticos para scrap 3 SCR');
    let listascrap=listaNeumaticoDisponible.filter((item)=>item.estado=='SCR')
    console.log(listascrap);

    console.log('Neumaticos para regresar a activo DSP');
    let listadisponible=listaNeumaticoDisponible.filter((item)=>item.estado=='DSP' && item.idvehiculo>0)
    console.log(listadisponible);

    const activo = 1;
    const usuario = usuarioctx.idusuario;
    
    let data = {unidad,asignado:listaasigando,reencauche:listareencauche,scrap:listascrap,disponible:listadisponible,activo,usuario};
    
      (async()=>{
        const respuesta = await ordenTrabajoGuardarPost(data);
        console.log(respuesta);
        if(respuesta!=null){               
          navigate('/ordentrabajo/');
        }else
        {
            console.log("acceso incorrecto vuelve a intentar");
        }

      })();
    }

  }
  function ObtenerNeumaticosDisponibles(estado){
    (async () => {
      const data = await neumaticoPorEstadoGet(estado);
      data.map((item,index)=>{
        item.estado='DSP';
        item.ver = true;
      })
      console.log(data);
      setListaNeumaticoDisponible(data);
    })();

  }

  useEffect(() => {
    ObtenerNeumaticosDisponibles(1);
   /* (async()=>{
        const dataposicion = await distribucionUnidadTipo(unidad);
        console.log(dataposicion);
        setDistribucionUnidad(dataposicion);

    })();*/
  }, []);

  return (
    <>      
<Header />
      <Menu />
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Asignación de neumático</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Asigancion de neumático
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#f3f181",
                    flexDirection: "column",
                    alignItems: "center",
                    height: 300,
                  }}
                  key={2}
                >
                  <h5>Lista de reencauche</h5>
                  <div style={{ margin: 8 }}>
                    <Columna
                      droppableId={'REN'}
                      alto={250}
                      ancho={230}
                      tipoitem={1}
                      items={listaNeumaticoDisponible}
                      isDragging={isDragging}
                      handleDragging={handleDragging}
                      handleUpdateList={handleUpdateList}
                    />
                  </div>
                </div>

                <br />
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#e06f6f",
                    flexDirection: "column",
                    alignItems: "center",
                    height: 300,
                  }}
                  key={3}
                >
                  <h5>Lista de scrap</h5>
                  <div style={{ margin: 8 }}>
                    <Columna
                      droppableId={'SCR'}
                      alto={250}
                      ancho={230}
                      tipoitem={1}
                      items={listaNeumaticoDisponible}
                      isDragging={isDragging}
                      handleDragging={handleDragging}
                      handleUpdateList={handleUpdateList}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label>Seleccionar una unidad:</label>
                <div className="row">
                <Select className="col-9" options={vehiculos} defaultValue={unidad} onChange={onUnidadChange} onInputChange={onBusquedaUnidad}/>
                <ExportarExcelAsignacionNeumatico data={dataasigancion} />
                </div>
                
                <div className="distribucion row">
                    <div className="col-3">
                      <br></br>
                      <h5>Área de trabajo</h5>
                      <div style={{width:"auto"}}>
                      
                      <Columna
                      droppableId={'TRB'}
                      alto={500}
                      ancho={50}
                      tipoitem={1}
                      items={listaNeumaticoDisponible}
                      isDragging={isDragging}
                      handleDragging={handleDragging}
                      handleUpdateList={handleUpdateList}
                    />

                      </div>
                    
                    </div>
                  <div className="col-6" style={{width:"260px"}}>
                    {(distribucionUnidad?.imagen != null)? <img
                      src={"dist/img/"+distribucionUnidad?.imagen}
                      style={{ width: "260px" }}
                    />: <></>}
                    
                    {distribucionUnidad?.posicion?.map((posicion)=>{
                        return(
                            <div
                      style={{
                        width: posicion.ancho,
                        height: 90,
                        position: "absolute",
                        top: posicion.ptop,
                        left: posicion.pleft,
                      }}
                    >
                      <Posicion
                        droppableId={posicion.posicion}
                        ancho={posicion.ancho}
                        tipoitem={2}
                        items={listaNeumaticoDisponible}
                        isDragging={isDragging}
                        handleDragging={handleDragging}
                        handleUpdateList={handleUpdateList}
                      />
                    </div>
                        );
                    })}


                    
                  </div>
                  <div className="col-3">
                    {distribucionUnidad?.repuesto?.map((posicion) =>{
                      return(
                        <div style={{padding:"20px"}}>
                  <h6>Llanta de Repuesto {posicion.nombre}</h6>
                              <Repuesto
                                droppableId={posicion.posicion}
                                tipoitem={2}
                                items={listaNeumaticoDisponible}
                                isDragging={isDragging}
                                handleDragging={handleDragging}
                                handleUpdateList={handleUpdateList}
                              />
                  </div>
                      );
                    })}
                    </div>
                  {/*<div style={{width:"100%",height:"100%",backgroundImage:`url("dist/img/camion-2j.png")`,backgroundRepeat:"no-repeat"}}>
                                        </div>*/}
                </div>
              </div>
              <div className="col-md-3">
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#a8dda9",
                    flexDirection: "column",
                    alignItems: "center",
                    height: 630,
                  }}
                  key={3}
                >
                  <h5>Lista de neumáticos disponibles</h5>
                  <div style={{ margin: 8 }}>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" >Filtro</span>
                      </div>
                      <input type="text" className="form-control" value={filtroneumatico} onChange={onFiltroNeumaticoChange} />
                    </div>
                    <Columna
                      droppableId={'DSP'}
                      alto={580}
                      ancho={230}
                      tipoitem={1}
                      items={listaNeumaticoDisponible}
                      isDragging={isDragging}
                      handleDragging={handleDragging}
                      handleUpdateList={handleUpdateList}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <button type="submit" className="btn btn-primary" onClick={handleGuargarOrdenTrabajo} >Guardar Asiganción</button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
</>
  );
}
