import React, { useEffect, useState } from 'react';
import Footer from '../../component/Footer';
import Header from '../../component/Header';
import Menu from '../../component/Menu';
import { neumatico, neumaticoExcel, neumaticoPaginacion } from '../../services/neumaticos';
import ModalEnvioReencauche from './componentes/ModalEnvioReencauche';
import ExportarExcelNeumatico from './componentes/ExportarExcelNeumatico';
import ModalEliminar from './componentes/ModalEliminar';
import Paginacion from '../../component/Paginacion';

export default function Neumatico(){
    const [listaNeumatico,setListaNeumatico]= useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    const [filtrocodigo,setFiltroCodigo] = useState('');
    const [filtrounidad,setFiltroUnidad] = useState('');
    const [filtromaxrem,setFiltroMaxRem] = useState('');
    const [filtrominrem,setFiltroMinRem] = useState('');
    const [neumaticoseleccion,setNeumaticoSeleccion] = useState([]);    
    const [data,setData] = useState([]);
    const [mensajevacio,setMensajeVacio] = useState(true);

    const onFiltroCodigo = e =>setFiltroCodigo(e.target.value);
    const onFiltroUnidad = e => setFiltroUnidad(e.target.value);
    const onFiltroMaxRem = e => setFiltroMaxRem(e.target.value);
    const onFiltroMinRem = e => setFiltroMinRem(e.target.value);

    const handleNeumaticoSeleccion = (neumatico) =>{
      setNeumaticoSeleccion(neumatico);
    } 

    function handleConsultar(pagina){
      (async()=>{
        const resultado = await neumaticoPaginacion(pagina,filtrocodigo,0,filtrounidad,filtromaxrem,filtrominrem);
        console.log(resultado);
        setPaginaActual(resultado.paginaactual);
        setPaginaTotal(resultado.paginastotal);
        
        setListaNeumatico(resultado.neumatico);           
    })();
    handlePaginacionActiva(pagina);
    }

    function handlePaginacionActiva(pagina){
      let temp=[...paginacion];

      temp.map((item)=>{
        if(item.i==pagina){
          item.activo='active';
        }else{
          item.activo='';
        }
      })
        

            setPaginacion(temp);
            console.log(temp);
    }



    const handleAnterior = e =>{
      e.preventDefault();
      let actual = paginaactual;
      if(actual>1){
        handleConsultar(actual-1);
      }
    }
    const handleSiguiente = e =>{
      e.preventDefault();
      let actual = paginaactual;
      if(actual<paginatotal){
        handleConsultar(actual+1);
      }
    }

    const handleFiltrar = e =>{
      e.preventDefault();
      ObtenerNeumaticos(1,filtrocodigo,filtrounidad,filtromaxrem,filtrominrem);
      ObtenerDataExcel(filtrocodigo,filtrounidad,filtromaxrem,filtrominrem);
    }

    const handleLimpiar = e =>{
      e.preventDefault();
      setFiltroCodigo('');
      setFiltroUnidad('');
      setFiltroMaxRem('');
      setFiltroMinRem('');
      ObtenerNeumaticos(1,'','','','');
      ObtenerDataExcel('','','','');
    }

    function ObtenerNeumaticos(pagina,codigo,unidad,maxrem,minrem){
      (async()=>{
        const resultado = await neumaticoPaginacion(pagina,codigo,0,unidad,maxrem,minrem);
        console.log(resultado);
        if(resultado == null){
          setListaNeumatico([]);
          setPaginacion([]);
          setMensajeVacio(false);
        }else{
          setMensajeVacio(true);
        setPaginaActual(resultado.paginaactual);
        setPaginaTotal(resultado.paginastotal);
        
        setListaNeumatico(resultado.neumatico);
        setPaginacion([]);
        let temp=[];
        let activo='active';
        for(let i=1;i<=resultado.paginastotal;i++){
          if(i==paginaactual){
          temp.push({i,activo});
          }else{
            temp.push({i,activo:''});
          }
        }

        setPaginacion(temp);   
        console.log(temp);  
      } 
    })();

    }
    function ObtenerDataExcel(codigo,unidad,maxrem,minrem){
      (async()=>{
        const resultado = await neumaticoExcel(codigo,0,unidad,maxrem,minrem);
        setData(resultado);            
    })();
    }

    useEffect(()=>{
      ObtenerNeumaticos(1,filtrocodigo,filtrounidad,filtromaxrem,filtrominrem);
      ObtenerDataExcel(filtrocodigo,filtrounidad,filtromaxrem,filtrominrem);
  },[]);

return(
  <>
  <Header />
  <Menu />  
  <div>
        <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">Neumático</h1>
          </div>{/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item active">Neumático</li>
            </ol>
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">


        <div className="col-md-12">
            <div className="card card-success">
              <div className="card-header">
                <h3 className="card-title">Lista de neumáticos</h3>
                <a href='/neumatico/registrar' className="btn-sm btn-dark float-sm-right">+ Registrar neumático</a>
              </div>
              <div className="card-body">
                <div className='row'>
                  <div className='col-md-3 col-sm-12'>
                    Código
                    <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder='Ingrese código a filtrar' value={filtrocodigo} onChange={onFiltroCodigo} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fas fa-check"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-sm-12'>
                    Unidad
                    <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder='Ingrese la placa' value={filtrounidad} onChange={onFiltroUnidad} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-truck"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    Max REM
                    <div className="input-group mb-3">
                    <input type="number" className="form-control" placeholder='REM Max' value={filtromaxrem} onChange={onFiltroMaxRem} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-truck"></i></span>
                    </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-12'>
                    Min REM
                    <div className="input-group mb-3">
                    <input type="number" className="form-control" placeholder='REM Min' value={filtrominrem} onChange={onFiltroMinRem} />
                    <div className="input-group-append">
                      <span className="input-group-text"><i className="fa fa-truck"></i></span>
                    </div>
                    </div>
                  </div>

                </div>
                <br/>
                <div className='row'>
                  
                    
                    <div className='col-sm-6 col-md-4'>
                    <button type='button' className='btn btn-success form-control' onClick={handleFiltrar} ><i className="fa fa-search" aria-hidden="true"></i> Buscar</button>
                    </div>

                    <div className='col-sm-6 col-md-4' >
                    <button className='btn btn-primary form-control' onClick={handleLimpiar} ><i className="fa fa-eraser"></i> Limpiar</button>
                    </div>

                    <div className='col-sm-6 col-md-4'>
                    <ExportarExcelNeumatico data={data}/>
                     
                    </div>

                </div>
              </div>
      
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{width: "50px"}}>Id</th>
                      <th>Codigo</th>
                      <th>Serie</th>
                      <th>Marca</th>
                      <th>Condición</th>
                      <th>Medida</th>
                      <th>Cocada</th>
                      <th>Estado</th>
                      <th>Unidad</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>

                    {listaNeumatico.map((item) => (
                        <tr>
                          <td>{item.idneumatico}</td>
                          <td>{item.codigo}</td>
                          <td>{item.serie}</td>
                          <td>{item.marca}</td>
                          <td>{(item.condicion==1)?"Nuevo":"Reencauchado"}</td>
                          <td>{item.medida}</td>
                          <td>{item.rem1} - {item.rem2} - {item.rem3} {(item.rem1<6 || item.rem2<6 || item.rem3 <6)?<label className='text-danger'>(Bajo)</label>:<></>}</td>
                          <td>{(item.estado==1)?<label className='text-success'>Disponible</label>:(item.estado==2)?<label className='text-primary'>Asignado</label>:(item.estado==3)? (item.subestado==2)? <label className='text-warning'>Reencauche - Enviado</label> :<label className='text-warning'>Reencauche</label> :(item.estado==4)?(item.subestado==1)? <label className='text-danger'>Scrap</label>  :<label className='text-default'>(Baja)</label>:<label className='text-default'>Sin estado validado</label>}</td>
                          <td>{item.vehiculo.placa}</td>
                          <td><a href={'/neumatico/ver/?idneumatico='+item.idneumatico} className='btn-sm btn-success'><i className="fa fa-eye" aria-hidden="true"></i> Ver </a>  
                              {(item.estado==1)?<button className="btn-sm btn-warning" data-toggle="modal" data-target="#modalEnvioReencauche" onClick={()=>handleNeumaticoSeleccion(item)}><i className="fa fa-retweet" aria-hidden="true"> Reencauche</i></button> :<></>}
                              {(item.estado!=2)?<button className="btn-sm btn-danger" data-toggle="modal" data-target="#modalEliminar" onClick={()=>handleNeumaticoSeleccion(item)} style={{padding:"3px",border:"none"}}><i className="fa fa-trash" aria-hidden="true"> Eliminar</i></button> :<></>}
                          </td>
                        </tr>
                    ))}                    
                  </tbody>
                </table>
                  <div hidden={mensajevacio}>
                    <h5 className='text-danger text-center'>No se encontron neumaticos.</h5>
                  </div>
              </div>
   
              <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                  <Paginacion paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                  <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                </ul>
              </div>
            </div>
   

            

        </div>
        
        </div>

      </div>{/* /.container-fluid */}
    </section>
  </div>
  <ModalEnvioReencauche neumatico={neumaticoseleccion} obtenerneumaticos={ObtenerNeumaticos} />
  <ModalEliminar neumatico={neumaticoseleccion} obtenerneumaticos={ObtenerNeumaticos} />
  </div>
  <Footer/>
  </>

);

}