import React, { useEffect, useState } from "react";
import { CSVLink} from 'react-csv';

export default function ExportarExcelScrap({data}){
    return(
        <CSVLink  data={ data} filename="scrap"  className="btn btn-secondary form-control"><i className="fa fa-file-excel" aria-hidden="true"></i> Exportar excel</CSVLink>
    );



} 