import React from "react";


function Llanta({item,index,ancho,handleDragging}){
    const handleDragStart= (e) =>{
        e.dataTransfer.setData('id',item.idneumatico)
        handleDragging(true)
      }
    
      const handleDragEnd=() =>handleDragging(false)

    return(
                    <div draggable
                    onDragStart={handleDragStart} 
                    onDragEnd={handleDragEnd}
                    >
                        <div className="conten-card-llanta">
                            <img width={ancho-4}
                            src="dist/img/llanta.jpg"
                            //src="https://www.llantamaya.com/wp/wp-content/uploads/2019/09/01005101741-1.png"
                            alt="llanta"
                            className="logo-llanta"/>
                            <span>
                                Codigo:{item.codigo}<br/>
                                Marca:{item.marca}<br/>
                                Medida:{item.medida}<br/>
                                Diseño:{item.disenio}<br/>
                            </span>
                        </div>
                    </div>
                );
           


}

export default Llanta;