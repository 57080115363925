const URLInspeccionGetAll = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccion";
const URLInspeccionPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccion/paginacion";
const URLInspeccionPorId = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccion/";
const URLInspeccionGuardarPost = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccion/store";
const URLInspeccionEliminar = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccion/eliminar";
const URLInspeccionExcel = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccionexcel";
const URLInspeccionExcelDetallado = process.env.REACT_APP_NEUMATICO_API_URL+"inspeccionexceldetallado";


export async function inspeccionGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    /*
    const response = await fetch(URLNeumaticoGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.neumaticos;
    }else{
        return null;
    }
    */
   const listainspeccion=[
    {id:1,unidad:"V2F-998",fecha:"11-11-2022",operador:"Operador 1"},
    {id:2,unidad:"D0G-968",fecha:"11-11-2022",operador:"Operador 1"},
    {id:3,unidad:"T4H-598",fecha:"11-11-2022",operador:"Operador 1"}
   ];
   
   return listainspeccion;
}

export async function inspeccionExcel(unidad,fechainspeccioninicio,fechainspeccionfin){
    const data = {unidad,fechainspeccioninicio,fechainspeccionfin}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLInspeccionExcel,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.inspeccion;
    }else{
        return null;
    }

}

export async function inspeccionExcelDetallado(unidad,fechainspeccioninicio,fechainspeccionfin){
    const data = {unidad,fechainspeccioninicio,fechainspeccionfin}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLInspeccionExcelDetallado,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.inspeccion;
    }else{
        return null;
    }

}

export async function inspeccionPaginacion(pagina,unidad,fechainspeccioninicio,fechainspeccionfin){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad,unidad,fechainspeccioninicio,fechainspeccionfin}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLInspeccionPaginacion,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }
}

export async function inspeccionPorId(idinspeccion){
    const requestOptions={
        method: "GET",
        headers: {"Content-Type": "application/json"}
    }
    const response = await fetch(URLInspeccionPorId+idinspeccion,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.inspeccion;
    }else{
        return null;
    }
}

export async function inspeccionGuardarPost(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLInspeccionGuardarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}

export async function inspeccionEliminar(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLInspeccionEliminar,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
}