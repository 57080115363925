const URLEmpleadoComboPost = process.env.REACT_APP_NEUMATICO_API_URL +"empleado/comboempleado";
const URLOperadorComboPost = process.env.REACT_APP_NEUMATICO_API_URL + "empleado/combooperador";

export async function EmpleadoCombo(nombre){
    const data={nombre};
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLEmpleadoComboPost,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.empleado;
    }else{
        return null;
    }
}

export async function OperadorCombo(nombre){
    const data={nombre};
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    const response = await fetch(URLOperadorComboPost,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson.operador;
    }else{
        return null;
    }
}