import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Menu from "../../component/Menu";
import Paginacion from "../../component/Paginacion";
import { recepcionPaginacion } from "../../services/recepcion";

export default function RecepcionNeumatico(){
    const [listaRecepcion,setListaRecepcion] = useState([]);
    const [paginaactual,setPaginaActual]= useState(1);
    const [paginatotal,setPaginaTotal] = useState(1);
    const [paginacion,setPaginacion] = useState([]);

    function ObtenerRecepcion(pagina){
        (async()=>{
          const resultado = await recepcionPaginacion(pagina);
          console.log(resultado);
          if(resultado == null){
            setListaRecepcion([]);
          setPaginacion([]);
          }else{
            setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaRecepcion(resultado.recepcion);
          setPaginacion([]);
          let temp=[];
          let activo='active';
          for(let i=1;i<=resultado.paginastotal;i++){
            if(i==paginaactual){
            temp.push({i,activo});
            }else{
              temp.push({i,activo:''});
            }
          }
          setPaginacion(temp);   
          }
             
        })();
    }

    function handleConsultar(pagina){
        (async()=>{
          const resultado = await recepcionPaginacion(pagina);
          setPaginaActual(resultado.paginaactual);
          setPaginaTotal(resultado.paginastotal);
          
          setListaRecepcion(resultado.recepcion);           
        })();
        handlePaginacionActiva(pagina);
    }

    function handlePaginacionActiva(pagina){
        let temp=[...paginacion];
  
        temp.map((item)=>{
          if(item.i==pagina){
            item.activo='active';
          }else{
            item.activo='';
          }
        })
        setPaginacion(temp);
    }
  
    const handleAnterior = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual>1){
          handleConsultar(actual-1);
        }
    }
    const handleSiguiente = e =>{
        e.preventDefault();
        let actual = paginaactual;
        if(actual<paginatotal){
          handleConsultar(actual+1);
        }
    }



    useEffect(()=>{
        ObtenerRecepcion(1);
    },[])
    
    return(
        <>      
<Header />
      <Menu />
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Recepción de neumáticos</h1>
                        </div>{/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active">Recepción neumático</li>
                            </ol>
                        </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Lista de recepción de neumáticos </h3>
                                        <a href='/registrorecepcion' className="btn btn-primary float-sm-right">Registrar recepción</a>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Guia remitente</th>
                                                    <th>Fecha</th>
                                                    <th>Proveedor</th>
                                                    <th>Ver</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listaRecepcion.map((recepcion)=>(
                                                    <tr>
                                                        <td>{recepcion.idrecepcionreencauche}</td>
                                                        <td>{recepcion.guiaremitente}</td>
                                                        <td>{recepcion.fecha}</td>
                                                        <td>{recepcion.proveedor}</td>
                                                        <td>
                                                            <a href={'recepcion/ver/?idrecepcion='+recepcion.idrecepcionreencauche} className="btn-sm btn-success"><i className="fa fa-eye" aria-hidden="true"></i> Ver</a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div hidden={(paginacion.length>0)? true: false}>
                                            <h5 className='text-danger text-center'>No se encontron recepciones.</h5>
                                        </div>
                                    </div>
                                    <div className="card-footer clearfix" hidden={(paginacion.length>0)? false: true}>
                                        <ul className="pagination pagination-sm m-0 float-right">
                                            <li key="btnAnterior" className="page-item"><button className="page-link" onClick={handleAnterior}>&laquo;</button></li>
                                            <Paginacion paginacion={paginacion} paginaactual={paginaactual} paginatotal={paginatotal} handleConsultar={handleConsultar}/>
                                            <li Key="btnSiguiente" className="page-item"><button className="page-link" onClick={handleSiguiente}>&raquo;</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer/>
</>
    );
}