const URLRecepcionGetAll = process.env.REACT_APP_NEUMATICO_API_URL+"recepcion";
const URLRecepcionPaginacion = process.env.REACT_APP_NEUMATICO_API_URL+"recepcion/paginacion";
const URLRecepcionPorId = process.env.REACT_APP_NEUMATICO_API_URL+"recepcion/";
const URLRecepcionGuardarPost = process.env.REACT_APP_NEUMATICO_API_URL+"recepcion/store";

export async function recepcionGetAll(){
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
    };
    
    const response = await fetch(URLRecepcionGetAll,requestOptions)
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.recepcion;
    }else{
        return null;
    }    
}

export async function recepcionPaginacion(pagina){
    const cantidad= process.env.REACT_APP_NEUMATICO_CONST_CANTIDADREGISTROS;
    const data = {pagina,cantidad}
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    
    const response = await fetch(URLRecepcionPaginacion,requestOptions)
    const responsejson = await response.json();
    console.log(responsejson);
    if(responsejson.rest==1){
        return responsejson;
    }else{
        return null;
    }

}

export async function recepcionPorId(idrecepcion){
    const requestOptions={
        method: "GET",
        headers: {"Content-Type": "application/json"}
    }
    const response = await fetch(URLRecepcionPorId+idrecepcion,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return responsejson.recepcion;
    }else{
        return null;
    }
}

export async function recepcionGuardarPost(data){
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };
    console.log(data);
    
    const response = await fetch(URLRecepcionGuardarPost,requestOptions);
    const responsejson = await response.json();
    if(responsejson.rest==1){
        return true;
    }else{
        return null;
    }
    
   return null;

}